import React from 'react';
import PropTypes from 'prop-types';

import './ScoreBar.css';

function ScoreBar({ value, isMini }) {
	const [text, color] = (() => {
		if (value === null) return ['', 'green'];
		if (value < 20) return ['very good!', 'green'];
		if (value < 50) return ['good', 'green'];
		if (value < 70) return ['okay', 'orange'];
		if (value < 90) return ['bad', 'red'];
		return ['very bad!', 'red'];
	})();

	if (value === null) {
		return (
			<div className={`score-bar ${isMini ? 'is-mini' : ''}`}>
				<span className="score-bar-na">N/A</span>
			</div>
		);
	}

	return (
		<div
			className={`score-bar ${isMini ? 'is-mini' : ''}`}
			style={{ '--width': `${value}%` }}
		>
			<span
				className="score-bar-label"
				style={{ '--color': `var(--${color}` }}
			>
				{text}
			</span>
			<div className="score-bar-bar" />
		</div>
	);
}

ScoreBar.propTypes = {
	isMini: PropTypes.bool,
	value: PropTypes.number,
};

ScoreBar.defaultProps = {
	isMini: false,
	value: null,
};

export default React.memo(ScoreBar);
