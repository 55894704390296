import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import App from './App';

import history from './services/history';
import { StoreProvider } from './services/use-local';
import { SiloProvider } from './services/use-silo';

import * as serviceWorker from './serviceWorker';

import 'normalize.css';

import packageJson from '../package.json';

global.appVersion = packageJson.version;
// eslint-disable-next-line no-console
console.log('Version:', global.appVersion);

ReactDOM.render(
	<Router history={history}>
		<StoreProvider>
			<SiloProvider>
				<App />
			</SiloProvider>
		</StoreProvider>
	</Router>,
	document.getElementById('root'),
);

serviceWorker.unregister();
