import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'interceptd-ui';

import defaultImage from '../../../../assets/icon.svg';

import './LargeAvatar.css';

const LargeAvatar = ({ src, alt, loading }) => (
	<div className={`large-avatar ${loading ? 'loading' : ''}`}>
		<Image
			src={src}
			alt={alt}
			defaultImage={defaultImage}
		/>
	</div>
);

LargeAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	loading: PropTypes.bool,
};

LargeAvatar.defaultProps = {
	loading: false,
};

export default React.memo(LargeAvatar);
