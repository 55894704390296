import React from 'react';
import PropTypes from 'prop-types';
import {
	Switch,
	Route as BrowserRoute,
	NavLink,
	Redirect,
	useLocation,
	Link,
} from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { Navigation, Footer } from 'interceptd-ui';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import Humanize from 'humanize-plus';
import Intercom from 'react-intercom';

import Avatar from './components/Avatar';

import {
	calculateFollowerCount,
	calculateAudienceScore,
	calculateEstimatedReach,
	calculateAuthenticEngagementScore,
} from './util/helpers/calculations';
import { getAvgEngRateMessage } from './util/misc';

import Api from './services/api';
import useAuth from './services/use-auth';
import useLocal, { getFromLocal } from './services/use-local';
import useSilo from './services/use-silo';

import config from './services/config.json';

import 'react-toastify/dist/ReactToastify.min.css';
import 'interceptd-ui/dist/interceptd-ui.css';
import './global.css';

import icon from './assets/icon-white.svg';

import { privateRoutes, publicRoutes } from './routes';

const Route = ({ component: Component, nested, ...rest }) => (
	<BrowserRoute
		{...rest}
		render={(props) => {
			if (
				privateRoutes.some((route) => isEqual(route.path, rest.path))
				&& !getFromLocal('token')
			) {
				return <Redirect to="/welcome" />;
			}

			if (!Component) return null;

			/* eslint-disable react/prop-types */
			return (
				<section className="page-wrapper">
					{props.location.pathname !== '/' && props.location.pathname.substr(-1) === '/' ? (
						<Redirect
							to={props.location.pathname.substr(0, props.location.pathname.length - 1)}
						/>
					) : (
						<Component {...props} />
					)}
				</section>
			);
			/* eslint-enable */
		}}
	/>
);

Route.propTypes = {
	component: PropTypes.elementType,
	nested: PropTypes.bool,
};

Route.defaultProps = {
	component: null,
	nested: false,
};

const intercomId = config[window.location.hostname].intercom;

export default function App() {
	const { store, setStoreItem, resetStore } = useLocal();
	const { setSiloItem, resetSilo } = useSilo();
	const { logout } = useAuth();
	const location = useLocation();

	React.useEffect(() => {
		const url = new URL(window.location.href);
		const token = url.searchParams.get('token');

		if (token) {
			Api.setToken(token);
			resetStore({
				login_as: false,
				token,
			});
		}
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		async function getMe() {
			if (!store?.token) return;
			try {
				const meReady = Api.getMe();
				const plansReady = Api.getPlans();

				const meResponse = await meReady;
				const planResponse = await plansReady;

				const plans = planResponse?.data?.data ?? {};
				const data = meResponse?.data?.data ?? {};
				const user = data.user ?? {};
				const account = data.account ?? {};
				const me = {
					...user,
					account_id: account.id,
					company_name: account.company_name,
					company_website: account.company_website,
					settings: {
						account: account.settings || {},
						user: user.settings || {},
					},
				};

				const influencerPlans = plans.influencer;
				const currentPlanName = account?.plans?.influencer?.name || 'free';

				const currentPlan = influencerPlans[currentPlanName]
					? {
						name: currentPlanName,
						price: influencerPlans[currentPlanName].price,
						limits: account?.plans?.influencer,
					} : {
						name: 'Trial',
						...influencerPlans.Trial,
					};

				setSiloItem('currentPlan', currentPlan);
				setSiloItem('plans', influencerPlans);

				setStoreItem('me', me);
				setStoreItem('settings', me.settings);
			} catch {
				logout();
			}
		}
		getMe();
		// eslint-disable-next-line
	}, [store?.token]);

	React.useEffect(() => {
		async function getData() {
			try {
				if (store?.token) {
					const [
						influencersResponse,
						campaignsResponse,
						sourcesResponse,
						mediasResponse,
					] = await Promise.all([
						Api.getUserInfluencers(),
						Api.getCampaigns(),
						Api.getSources(),
						Api.getMedias(),
					]);

					const campaigns = campaignsResponse?.data?.data || [];
					const rawMedias = mediasResponse?.data?.data || [];
					const rawInfluencer = influencersResponse?.data?.data || [];

					const medias = rawMedias.map((m) => {
						const media = m;
						media.influencer = rawInfluencer.find((inf) => inf.id === media.influencer_id);
						return media;
					});

					const siloCampaigns = sortBy(campaigns, ['id']).reverse().map((c) => {
						const campaign = c;
						const posts = medias.filter((m) => m.campaign_id === campaign.id && m.type !== 'link').map((m) => m.data);
						const campaignPosts = medias.filter((m) => m.campaign_id === campaign.id && m.type !== 'link');
						const rateList = [];

						const totalList = [];
						const realFollowers = {};
						let influencerName;
						campaignPosts.forEach((cp) => {
							influencerName = cp?.influencer?.username;
							const total = {
								like: 0,
								comment: 0,
								follower: 0,
							};
							const like = cp?.data?.likes_count || 0;
							const comment = cp?.data?.comment_count || 0;
							const follower = cp?.influencer?.data?.follower_count || 0;
							const audienceScore = cp?.influencer.audience_score;
							const { totalRealFollower } = calculateFollowerCount(audienceScore, follower);
							realFollowers[cp?.influencer?.id] = totalRealFollower;
							const rate = ((like + comment) / follower) * 100;
							total.like = like;
							total.comment = comment;
							total.follower = follower;
							totalList.push(total);
							rateList.push(rate);
						});

						const estimatedReach = calculateEstimatedReach({
							posts,
							realFollowers: Object.values(realFollowers).reduce((acc, curr) => acc + curr, 0),
						});
						const totalRate = rateList.reduce((acc, curr) => acc + curr, 0);
						const campaignEngagementRate = totalRate
							? (totalRate / rateList.length).toFixed(2)
							: null;

						campaign.estimatedReach = Number.isNaN(estimatedReach) ? null : estimatedReach;
						campaign.engagementRate = campaignEngagementRate;
						campaign.influencerName = influencerName;
						return campaign;
					});

					const siloMedias = sortBy(medias, ['id']).reverse();

					const influencers = rawInfluencer.map((inf) => {
						const influencer = inf;
						const followerCount = influencer?.data?.follower_count || 0;
						const posts = (influencer?.data?.media || []).filter((m) => m.type !== 'link');

						const totalEngagement = posts.reduce((acc, curr) => (
							acc + (curr?.comment_count || 0) + (curr?.likes_count || 0)
						), 0);

						influencer.engagement = (totalEngagement > 0 && followerCount > 0)
							? ((totalEngagement / (followerCount * posts.length)) * 100).toFixed(2)
							: null;

						influencer.averageRate = getAvgEngRateMessage(followerCount);

						influencer.nameColumn = (
							<div className="influencer-col">
								<Avatar src={influencer?.data?.profile_pic_url} alt={influencer.username} />
								<div className="influencer-col-info-wrapper">
									<Link to={`/influencer/${influencer?.type}/${influencer?.username}`} className="influencer-col-name">{`@${influencer?.username || 'deleted'}`}</Link>
									<span className="influencer-col-info">{influencer?.data?.follower_count ? Humanize.compactInteger(influencer?.data?.follower_count, 1).toUpperCase() : ''}</span>
								</div>
							</div>
						);

						influencer.posts = (influencer?.data?.media || [])
							.reduce((acc, curr) => curr.type !== 'link'
								? acc + 1
								: acc, 0);

						influencer.links = (influencer?.data?.media || [])
							.reduce((acc, curr) => curr.type === 'link'
								? acc + 1
								: acc, 0);

						const {
							qualifiedAudienceData,
							audienceAuthenticity,
						} = calculateAudienceScore(influencer.audience_score, followerCount);
						const authenticEngagement = calculateAuthenticEngagementScore(influencer.engagement_score, influencer.engagement);
						const { totalRealFollower } = calculateFollowerCount(influencer.audience_score, followerCount);
						const estimatedReach = calculateEstimatedReach({
							posts,
							realFollowers: totalRealFollower,
						});

						influencer.estimatedReach = Number.isNaN(estimatedReach) ? null : estimatedReach;
						influencer.qualifiedAudience = qualifiedAudienceData;
						influencer.audienceAuthenticity = audienceAuthenticity;
						influencer.authenticEngagementRate = authenticEngagement || null;

						return influencer;
					});

					const siloInfluencers = sortBy(rawInfluencer, ['id']).reverse();

					const sources = sourcesResponse?.data?.data || [];
					const siloSources = sortBy(sources, ['id']).reverse().map((s) => {
						const source = s;
						source.influencer = influencers.find((i) => i.id === source.influencer_id);
						return source;
					});

					setSiloItem('data', {
						loading: false,
						campaigns: siloCampaigns,
						medias: siloMedias,
						influencers: siloInfluencers,
						sources: siloSources,
					});
				} else {
					resetSilo();
				}
			} catch (err) {
				resetSilo();
				console.error(err);
			}
		}
		getData();
		// eslint-disable-next-line
	}, [store?.token]);

	React.useEffect(() => {
		window.scroll(0, 0);
	}, [location.pathname]);

	const getIntercomData = () => {
		if (store?.me) {
			const { me } = store;
			return ({
				user_id: me.id,
				email: me.email,
				name: me.fullname,
				'User Plan Type': me?.plans?.influencer?.name,
				created_at: me.ts_registration,
			});
		}

		return ({});
	};

	return (
		<div
			className={`
				interceptd
				${store?.token ? 'page-private' : ''}
				page${location.pathname.replace('/', '-')}
			`}
		>
			{store?.token && (
				<Navigation
					logo={(
						<NavLink to="/" className="navigation-logo">
							<img src={icon} alt="Interceptd Icon" />
						</NavLink>
					)}
					create={null}
					topMenuItems={[
						{
							component: NavLink,
							linkProps: {
								to: '/',
								activeClassName: 'is-active',
								exact: true,
							},
							icon: 'user-check',
							title: 'Influencer List',
						},
						{
							component: NavLink,
							linkProps: {
								to: '/campaign-list',
								activeClassName: 'is-active',
								exact: true,
							},
							icon: 'list',
							title: 'Campaigns',
						},
					]}
					bottomMenuItems={[]}
					user={store?.me ? {
						name: store?.me?.fullname,
						email: store?.me?.email,
						dropdownItems: [
							{
								component: 'a',
								linkProps: {
									href: `https://app.interceptd.com/switch/${store?.token}`,
								},
								icon: 'arrow-up-right',
								title: 'Switch to Interceptd',
							},
							{
								seperator: true,
							},
							{
								component: 'a',
								linkProps: {
									href: '/account/plans',
								},
								icon: 'dollar-sign',
								title: 'Plans',
							},
							{
								seperator: true,
							},
							// {
							// 	component: 'a',
							// 	linkProps: {
							// 		href: 'https://intercom.help/interceptd/en/',
							// 		target: '_blank',
							// 	},
							// 	icon: 'help-circle',
							// 	title: 'Help',
							// },
							// {
							// 	seperator: true,
							// },
							{
								component: 'a',
								linkProps: {
									href: '/glossary',
								},
								icon: 'book',
								title: 'Glossary',
							},
							{
								seperator: true,
							},
							{
								component: NavLink,
								linkProps: {
									to: '/logout',
									activeClassName: 'is-active',
									exact: true,
								},
								icon: 'log-out',
								title: 'Logout',
							},
						],
					} : null}
				/>
			)}

			<Switch>
				{privateRoutes
					.map((route, i) => (
						<Route
							key={i}
							{...route}
							nested={false}
							component={route.nested ? null : route.component}
						/>
					))}
				{publicRoutes.map((route, i) => <Route key={i} {...route} />)}
			</Switch>

			{store?.token && privateRoutes
				.filter((route) => route.nested)
				.map((route, i) => (
					<Route
						key={i}
						{...route}
					/>
				))}

			{store?.token && (
				<Footer />
			)}

			<ToastContainer
				position="bottom-left"
				transition={Slide}
				closeButton={false}
				newestOnTop
				autoClose={3000}
			/>

			{window.location.hostname.indexOf('local') === -1 && (
				<Intercom appID={intercomId} {...getIntercomData()} />
			)}
		</div>
	);
}
