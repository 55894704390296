import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import { useHistory } from 'react-router-dom';
import {
	Input,
	TagInput,
	Box,
	Icon,
	Tooltip,
	Info,
} from 'interceptd-ui';

import More from '../../components/More';

import Influencer from './components/Influencer';

import useSilo from '../../services/use-silo';

import './styles/Step3.css';

export default function Step3({
	seenFirst,
	sources,
	onSourceChange,
	onSourceRemove,
	onMediaChange,
	onMediaAdd,
	onMediaRemove,
	form,
	onFormChange,
}) {
	const history = useHistory();
	const { silo } = useSilo();

	const maxLinkLimit = silo?.currentPlan?.limits?.story_limit ?? 0;
	const totalLinkCount = silo?.data?.medias.filter((m) => m.type === 'link').length;
	const totalSourceMediaCount = sources.reduce((acc, curr) => {
		return acc + curr.medias.length;
	}, 0);
	const storyLeft = maxLinkLimit - totalLinkCount;

	useEffect(() => {
		if (!seenFirst) {
			history.push('/create');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seenFirst]);

	const handleMentions = (value) => {
		const newInf = uniq(
			value.map((item) => (item).trim().replace(/\s/g, '')),
		);
		onFormChange('mentions', newInf);
	};

	return (
		<div className="campaign-create-inner">
			<Input
				autoFocus
				required
				label="Campaign Name"
				placeholder="Influencer Campaign"
				name={`name-${Math.random().toString(36).substr(2, 9)}`}
				value={form.name}
				onChange={(event) => onFormChange('name', event.target.value)}
			/>
			<Input
				label="Campaign Landing URL"
				placeholder="https://example.com"
				name={`landing_url-${Math.random().toString(36).substr(2, 9)}`}
				value={form.landing_url}
				onChange={(event) => onFormChange('landing_url', event.target.value)}
			/>
			<TagInput
				name={`mentions-${Math.random().toString(36).substr(2, 9)}`}
				label="Campaign Hashtags and Mentions"
				placeholder="#love, @instagram"
				addOnBlur
				value={form.mentions}
				onChange={handleMentions}
			/>
			{sources.map((source) => (
				<Box
					key={source.name}
					title={source.name}
					className="influencer-source"
					right={sources.length > 1 ? (
						<Tooltip position="right" tooltip="Remove Influencer from Campaign">
							<Icon i="trash" size={18} onClick={() => onSourceRemove(source.name)} />
						</Tooltip>
					) : null}
				>
					<div className="influencer-source-info">
						<Influencer influencer={source} />
						<Input
							label={(
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<span>Cost ($)</span>
									<Info>The amount you pay influencer for the campaign</Info>
								</div>
							)}
							placeholder="1500"
							name={`cost-${source.name}-${Math.random().toString(36).substr(2, 9)}`}
							value={source.cost}
							onChange={(event) => onSourceChange(source.name, 'cost', event.target.value)}
						/>
					</div>
					<div className="influencer-source-medias">
						{source.medias.map((media, index) => (
							<div className="influencer-source-media" key={index}>
								<Input
									label={(
										<React.Fragment>
											Influencer's Landing URL
											<Info>This URL will be tracked and monitored</Info>
										</React.Fragment>
									)}
									placeholder={form.landing_url !== '' ? form.landing_url : 'https://example.com'}
									name={`media-${source.name}-${index}-${Math.random().toString(36).substr(2, 9)}`}
									value={media.landing_url}
									onChange={(event) => onMediaChange(source.name, index, 'landing_url', event.target.value)}
								/>
								<Tooltip position="right" tooltip="Remove URL">
									<Icon i="trash" size={18} onClick={() => onMediaRemove(source.name, index)} />
								</Tooltip>
							</div>
						))}
						{(storyLeft - totalSourceMediaCount) > 0 && (
							<More onClick={() => onMediaAdd(source.name)}>Add URL</More>
						)}
					</div>
				</Box>
			))}
		</div>
	);
}

Step3.propTypes = {
	seenFirst: PropTypes.bool.isRequired,
	sources: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	onSourceChange: PropTypes.func.isRequired,
	onSourceRemove: PropTypes.func.isRequired,
	onMediaChange: PropTypes.func.isRequired,
	onMediaAdd: PropTypes.func.isRequired,
	onMediaRemove: PropTypes.func.isRequired,
	form: PropTypes.shape().isRequired,
	onFormChange: PropTypes.func.isRequired,
};
