export function mentionAndHashtagIsExists(str = '') {
	return /@\w+/g.test(str) && /#\w+/g.test(str);
}

export function getAvgEngRateMessage(followerCount = 0) {
	let message = '';
	if (followerCount < 10000) {
		message = '1K< # followers <10K is 4.4%.';
	} else if (followerCount < 100000) {
		message = '10K< # followers <100K is 2.4%.';
	} else if (followerCount < 1000000) {
		message = '100K< # followers <1M is 1.8%.';
	} else {
		message = '1M< # followers is 0.7%.';
	}

	return `Avg. ER of Influencers with ${message}`;
}

export function median(values) {
	if (values.length === 0) return 0;

	values.sort((a, b) => a - b);

	const half = Math.floor(values.length / 2);

	if (values.length % 2) {
		return values[half];
	}

	return (values[half - 1] + values[half]) / 2.0;
}

export default {};
