import React from 'react';
import PropTypes from 'prop-types';
import ApexChart from 'react-apexcharts';
import { Box, NoData, Loader } from 'interceptd-ui';

import './Chart.css';

export const COLORS = [
	'#230060',
	'#5C6BC0',
	'#B9CDE1',
	'#FFC000',
	'#ED6D6D',
	'#55C4C2',
	'#036FDB',
	'#F3B071',
	'#EEF5FC',
	'#6D7782',
	'#4C5054',
	'#97989A',
	'#ECECEC',
	'#F8F8F8',
	'#FFC000',
	'#ED6D6D',
	'#55C4C2',
	'#036FDB',
	'#F3B071',
];

function Chart({
	title,
	series,
	loading,
	getOptions,
	options,
	fullWidth,
	right,
	...rest
}) {
	return (
		<Box className="chart-box" title={title} right={right}>
			{loading ? <Loader /> : (series.length === 0 || series.every((s) => s === 0))
				? (
					<NoData icon="box">No Data</NoData>
				) : (
					<div
						style={{
							display: fullWidth ? 'block' : 'inline-flex',
							flex: fullWidth ? 1 : null,
						}}
					>
						<ApexChart
							series={series}
							options={getOptions(options)}
							{...rest}
						/>
					</div>
				)}
		</Box>
	);
}

Chart.propTypes = {
	title: PropTypes.string,
	series: PropTypes.arrayOf(PropTypes.shape).isRequired,
	loading: PropTypes.bool,
	fullWidth: PropTypes.bool,
	getOptions: PropTypes.func,
	options: PropTypes.shape(),
};

Chart.defaultProps = {
	title: '',
	loading: false,
	fullWidth: false,
	getOptions: (options) => options,
	options: {
		chart: {
			type: 'line',
			height: 400,
		},
		colors: COLORS,
		stroke: {
			curve: 'smooth',
			width: 2,
		},
	},
};

export default React.memo(
	Chart,
);
