import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import Api from '../../services/api';
import useLocal from '../../services/use-local';

export default function Switch() {
	const { store, resetStore } = useLocal();
	const { token } = useParams();

	if (token) {
		resetStore({ token });
		Api.setToken(token);
		if (store?.token) {
			return <Redirect to="/" />;
		}

		return null;
	}

	return <Redirect to="/welcome" />;
}
