export default [
	{
		id: 2,
		name: 'Albania',
		alpha2_code: 'AL',
		is_visible: true,
	},
	{
		id: 3,
		name: 'Algeria',
		alpha2_code: 'DZ',
		is_visible: true,
	},
	{
		id: 5,
		name: 'Andorra',
		alpha2_code: 'AD',
		is_visible: true,
	},
	{
		id: 7,
		name: 'Anguilla',
		alpha2_code: 'AI',
		is_visible: true,
	},
	{
		id: 9,
		name: 'Antigua And Barbuda',
		alpha2_code: 'AG',
		is_visible: true,
	},
	{
		id: 10,
		name: 'Argentina',
		alpha2_code: 'AR',
		is_visible: true,
	},
	{
		id: 11,
		name: 'Armenia',
		alpha2_code: 'AM',
		is_visible: true,
	},
	{
		id: 13,
		name: 'Australia',
		alpha2_code: 'AU',
		is_visible: true,
	},
	{
		id: 14,
		name: 'Austria',
		alpha2_code: 'AT',
		is_visible: true,
	},
	{
		id: 15,
		name: 'Azerbaijan',
		alpha2_code: 'AZ',
		is_visible: true,
	},
	{
		id: 16,
		name: 'Bahamas',
		alpha2_code: 'BS',
		is_visible: true,
	},
	{
		id: 17,
		name: 'Bahrain',
		alpha2_code: 'BH',
		is_visible: true,
	},
	{
		id: 18,
		name: 'Bangladesh',
		alpha2_code: 'BD',
		is_visible: true,
	},
	{
		id: 19,
		name: 'Barbados',
		alpha2_code: 'BB',
		is_visible: true,
	},
	{
		id: 20,
		name: 'Belarus',
		alpha2_code: 'BY',
		is_visible: true,
	},
	{
		id: 21,
		name: 'Belgium',
		alpha2_code: 'BE',
		is_visible: true,
	},
	{
		id: 22,
		name: 'Belize',
		alpha2_code: 'BZ',
		is_visible: true,
	},
	{
		id: 23,
		name: 'Benin',
		alpha2_code: 'BJ',
		is_visible: true,
	},
	{
		id: 24,
		name: 'Bermuda',
		alpha2_code: 'BM',
		is_visible: true,
	},
	{
		id: 25,
		name: 'Bhutan',
		alpha2_code: 'BT',
		is_visible: true,
	},
	{
		id: 26,
		name: 'Bolivia',
		alpha2_code: 'BO',
		is_visible: true,
	},
	{
		id: 27,
		name: 'Bosnia And Herzegowina',
		alpha2_code: 'BA',
		is_visible: true,
	},
	{
		id: 28,
		name: 'Botswana',
		alpha2_code: 'BW',
		is_visible: true,
	},
	{
		id: 30,
		name: 'Brazil',
		alpha2_code: 'BR',
		is_visible: true,
	},
	{
		id: 32,
		name: 'Brunei',
		alpha2_code: 'BN',
		is_visible: true,
	},
	{
		id: 33,
		name: 'Bulgaria',
		alpha2_code: 'BG',
		is_visible: true,
	},
	{
		id: 34,
		name: 'Burkina Faso',
		alpha2_code: 'BF',
		is_visible: true,
	},
	{
		id: 36,
		name: 'Cambodia',
		alpha2_code: 'KH',
		is_visible: true,
	},
	{
		id: 38,
		name: 'Canada',
		alpha2_code: 'CA',
		is_visible: true,
	},
	{
		id: 39,
		name: 'Cape Verde',
		alpha2_code: 'CV',
		is_visible: true,
	},
	{
		id: 40,
		name: 'Cayman Islands',
		alpha2_code: 'KY',
		is_visible: true,
	},
	{
		id: 42,
		name: 'Chad',
		alpha2_code: 'TD',
		is_visible: true,
	},
	{
		id: 43,
		name: 'Chile',
		alpha2_code: 'CL',
		is_visible: true,
	},
	{
		id: 44,
		name: 'China',
		alpha2_code: 'CN',
		is_visible: true,
	},
	{
		id: 47,
		name: 'Colombia',
		alpha2_code: 'CO',
		is_visible: true,
	},
	{
		id: 49,
		name: 'Congo',
		alpha2_code: 'CG',
		is_visible: true,
	},
	{
		id: 51,
		name: 'Costa Rica',
		alpha2_code: 'CR',
		is_visible: true,
	},
	{
		id: 52,
		name: 'Cote D Ivoire',
		alpha2_code: 'CI',
		is_visible: true,
	},
	{
		id: 53,
		name: 'Croatia',
		alpha2_code: 'HR',
		is_visible: true,
	},
	{
		id: 55,
		name: 'Cyprus',
		alpha2_code: 'CY',
		is_visible: true,
	},
	{
		id: 56,
		name: 'Czech Republic',
		alpha2_code: 'CZ',
		is_visible: true,
	},
	{
		id: 57,
		name: 'Denmark',
		alpha2_code: 'DK',
		is_visible: true,
	},
	{
		id: 59,
		name: 'Dominica',
		alpha2_code: 'DM',
		is_visible: true,
	},
	{
		id: 60,
		name: 'Dominican Republic',
		alpha2_code: 'DO',
		is_visible: true,
	},
	{
		id: 61,
		name: 'Ecuador',
		alpha2_code: 'EC',
		is_visible: true,
	},
	{
		id: 62,
		name: 'Egypt',
		alpha2_code: 'EG',
		is_visible: true,
	},
	{
		id: 63,
		name: 'El Salvador',
		alpha2_code: 'SV',
		is_visible: true,
	},
	{
		id: 66,
		name: 'Estonia',
		alpha2_code: 'EE',
		is_visible: true,
	},
	{
		id: 70,
		name: 'Fiji',
		alpha2_code: 'FJ',
		is_visible: true,
	},
	{
		id: 71,
		name: 'Finland',
		alpha2_code: 'FI',
		is_visible: true,
	},
	{
		id: 72,
		name: 'France',
		alpha2_code: 'FR',
		is_visible: true,
	},
	{
		id: 78,
		name: 'Gambia',
		alpha2_code: 'GM',
		is_visible: true,
	},
	{
		id: 79,
		name: 'Georgia',
		alpha2_code: 'GE',
		is_visible: true,
	},
	{
		id: 80,
		name: 'Germany',
		alpha2_code: 'DE',
		is_visible: true,
	},
	{
		id: 81,
		name: 'Ghana',
		alpha2_code: 'GH',
		is_visible: true,
	},
	{
		id: 83,
		name: 'Greece',
		alpha2_code: 'GR',
		is_visible: true,
	},
	{
		id: 85,
		name: 'Grenada',
		alpha2_code: 'GD',
		is_visible: true,
	},
	{
		id: 88,
		name: 'Guatemala',
		alpha2_code: 'GT',
		is_visible: true,
	},
	{
		id: 89,
		name: 'Guinea',
		alpha2_code: 'GN',
		is_visible: true,
	},
	{
		id: 90,
		name: 'Guinea-Bissau',
		alpha2_code: 'GW',
		is_visible: true,
	},
	{
		id: 91,
		name: 'Guyana',
		alpha2_code: 'GY',
		is_visible: true,
	},
	{
		id: 94,
		name: 'Honduras',
		alpha2_code: 'HN',
		is_visible: true,
	},
	{
		id: 95,
		name: 'Hong Kong',
		alpha2_code: 'HK',
		is_visible: true,
	},
	{
		id: 96,
		name: 'Hungary',
		alpha2_code: 'HU',
		is_visible: true,
	},
	{
		id: 97,
		name: 'Iceland',
		alpha2_code: 'IS',
		is_visible: true,
	},
	{
		id: 98,
		name: 'India',
		alpha2_code: 'IN',
		is_visible: true,
	},
	{
		id: 99,
		name: 'Indonesia',
		alpha2_code: 'ID',
		is_visible: true,
	},
	{
		id: 100,
		name: 'Iran, Islamic Republic of',
		alpha2_code: 'IR',
		is_visible: true,
	},
	{
		id: 101,
		name: 'Iraq',
		alpha2_code: 'IQ',
		is_visible: true,
	},
	{
		id: 102,
		name: 'Ireland',
		alpha2_code: 'IE',
		is_visible: true,
	},
	{
		id: 103,
		name: 'Israel',
		alpha2_code: 'IL',
		is_visible: true,
	},
	{
		id: 104,
		name: 'Italy',
		alpha2_code: 'IT',
		is_visible: true,
	},
	{
		id: 105,
		name: 'Jamaica',
		alpha2_code: 'JM',
		is_visible: true,
	},
	{
		id: 106,
		name: 'Japan',
		alpha2_code: 'JP',
		is_visible: true,
	},
	{
		id: 107,
		name: 'Jordan',
		alpha2_code: 'JO',
		is_visible: true,
	},
	{
		id: 108,
		name: 'Kazakhstan',
		alpha2_code: 'KZ',
		is_visible: true,
	},
	{
		id: 109,
		name: 'Kenya',
		alpha2_code: 'KE',
		is_visible: true,
	},
	{
		id: 111,
		name: 'Korea - South',
		alpha2_code: 'KR',
		is_visible: true,
	},
	{
		id: 113,
		name: 'Kuwait',
		alpha2_code: 'KW',
		is_visible: true,
	},
	{
		id: 114,
		name: 'Kyrgyzstan',
		alpha2_code: 'KG',
		is_visible: true,
	},
	{
		id: 116,
		name: 'Latvia',
		alpha2_code: 'LV',
		is_visible: true,
	},
	{
		id: 117,
		name: 'Lebanon',
		alpha2_code: 'LB',
		is_visible: true,
	},
	{
		id: 119,
		name: 'Liberia',
		alpha2_code: 'LR',
		is_visible: true,
	},
	{
		id: 120,
		name: 'Libya',
		alpha2_code: 'LY',
		is_visible: true,
	},
	{
		id: 122,
		name: 'Lithuania',
		alpha2_code: 'LT',
		is_visible: true,
	},
	{
		id: 123,
		name: 'Luxembourg',
		alpha2_code: 'LU',
		is_visible: true,
	},
	{
		id: 124,
		name: 'Macau',
		alpha2_code: 'MO',
		is_visible: true,
	},
	{
		id: 125,
		name: 'Macedonia',
		alpha2_code: 'MK',
		is_visible: true,
	},
	{
		id: 126,
		name: 'Madagascar',
		alpha2_code: 'MG',
		is_visible: true,
	},
	{
		id: 127,
		name: 'Malawi',
		alpha2_code: 'MW',
		is_visible: true,
	},
	{
		id: 128,
		name: 'Malaysia',
		alpha2_code: 'MY',
		is_visible: true,
	},
	{
		id: 129,
		name: 'Maldives',
		alpha2_code: 'MV',
		is_visible: true,
	},
	{
		id: 130,
		name: 'Mali',
		alpha2_code: 'ML',
		is_visible: true,
	},
	{
		id: 131,
		name: 'Malta',
		alpha2_code: 'MT',
		is_visible: true,
	},
	{
		id: 132,
		name: 'Marshall Islands',
		alpha2_code: 'MH',
		is_visible: true,
	},
	{
		id: 133,
		name: 'Martinique',
		alpha2_code: 'MQ',
		is_visible: true,
	},
	{
		id: 134,
		name: 'Mauritania',
		alpha2_code: 'MR',
		is_visible: true,
	},
	{
		id: 135,
		name: 'Mauritius',
		alpha2_code: 'MU',
		is_visible: true,
	},
	{
		id: 136,
		name: 'Mayotte',
		alpha2_code: 'YT',
		is_visible: true,
	},
	{
		id: 137,
		name: 'Mexico',
		alpha2_code: 'MX',
		is_visible: true,
	},
	{
		id: 138,
		name: 'Micronesia, Federated States Of',
		alpha2_code: 'FM',
		is_visible: true,
	},
	{
		id: 139,
		name: 'Moldova - Republic Of',
		alpha2_code: 'MD',
		is_visible: true,
	},
	{
		id: 140,
		name: 'Monaco',
		alpha2_code: 'MC',
		is_visible: true,
	},
	{
		id: 141,
		name: 'Mongolia',
		alpha2_code: 'MN',
		is_visible: true,
	},
	{
		id: 142,
		name: 'Montserrat',
		alpha2_code: 'MS',
		is_visible: true,
	},
	{
		id: 143,
		name: 'Morocco',
		alpha2_code: 'MA',
		is_visible: true,
	},
	{
		id: 144,
		name: 'Mozambique',
		alpha2_code: 'MZ',
		is_visible: true,
	},
	{
		id: 145,
		name: 'Myanmar',
		alpha2_code: 'MM',
		is_visible: true,
	},
	{
		id: 146,
		name: 'Namibia',
		alpha2_code: 'NA',
		is_visible: true,
	},
	{
		id: 147,
		name: 'Nauru',
		alpha2_code: 'NR',
		is_visible: true,
	},
	{
		id: 148,
		name: 'Nepal',
		alpha2_code: 'NP',
		is_visible: true,
	},
	{
		id: 149,
		name: 'Netherlands',
		alpha2_code: 'NL',
		is_visible: true,
	},
	{
		id: 150,
		name: 'Netherlands Antilles',
		alpha2_code: 'AN',
		is_visible: true,
	},
	{
		id: 151,
		name: 'New Caledonia',
		alpha2_code: 'NC',
		is_visible: true,
	},
	{
		id: 152,
		name: 'New Zealand',
		alpha2_code: 'NZ',
		is_visible: true,
	},
	{
		id: 153,
		name: 'Nicaragua',
		alpha2_code: 'NI',
		is_visible: true,
	},
	{
		id: 154,
		name: 'Niger',
		alpha2_code: 'NE',
		is_visible: true,
	},
	{
		id: 155,
		name: 'Nigeria',
		alpha2_code: 'NG',
		is_visible: true,
	},
	{
		id: 156,
		name: 'Niue',
		alpha2_code: 'NU',
		is_visible: true,
	},
	{
		id: 157,
		name: 'Norfolk Island',
		alpha2_code: 'NF',
		is_visible: true,
	},
	{
		id: 158,
		name: 'Northern Mariana Islands',
		alpha2_code: 'MP',
		is_visible: true,
	},
	{
		id: 159,
		name: 'Norway',
		alpha2_code: 'NO',
		is_visible: true,
	},
	{
		id: 160,
		name: 'Oman',
		alpha2_code: 'OM',
		is_visible: true,
	},
	{
		id: 161,
		name: 'Pakistan',
		alpha2_code: 'PK',
		is_visible: true,
	},
	{
		id: 162,
		name: 'Palau',
		alpha2_code: 'PW',
		is_visible: true,
	},
	{
		id: 163,
		name: 'Panama',
		alpha2_code: 'PA',
		is_visible: true,
	},
	{
		id: 164,
		name: 'Papua New Guinea',
		alpha2_code: 'PG',
		is_visible: true,
	},
	{
		id: 165,
		name: 'Paraguay',
		alpha2_code: 'PY',
		is_visible: true,
	},
	{
		id: 166,
		name: 'Peru',
		alpha2_code: 'PE',
		is_visible: true,
	},
	{
		id: 167,
		name: 'Philippines',
		alpha2_code: 'PH',
		is_visible: true,
	},
	{
		id: 168,
		name: 'Pitcairn',
		alpha2_code: 'PN',
		is_visible: true,
	},
	{
		id: 169,
		name: 'Poland',
		alpha2_code: 'PL',
		is_visible: true,
	},
	{
		id: 170,
		name: 'Portugal',
		alpha2_code: 'PT',
		is_visible: true,
	},
	{
		id: 171,
		name: 'Puerto Rico',
		alpha2_code: 'PR',
		is_visible: true,
	},
	{
		id: 172,
		name: 'Qatar',
		alpha2_code: 'QA',
		is_visible: true,
	},
	{
		id: 173,
		name: 'Reunion',
		alpha2_code: 'RE',
		is_visible: true,
	},
	{
		id: 174,
		name: 'Romania',
		alpha2_code: 'RO',
		is_visible: true,
	},
	{
		id: 175,
		name: 'Russia',
		alpha2_code: 'RU',
		is_visible: true,
	},
	{
		id: 177,
		name: 'Saint Helena',
		alpha2_code: 'SH',
		is_visible: true,
	},
	{
		id: 178,
		name: 'Saint Kitts And Nevis',
		alpha2_code: 'KN',
		is_visible: true,
	},
	{
		id: 179,
		name: 'Saint Lucia',
		alpha2_code: 'LC',
		is_visible: true,
	},
	{
		id: 181,
		name: 'Saint Vincent and the Grenadines',
		alpha2_code: 'VC',
		is_visible: true,
	},
	{
		id: 184,
		name: 'Sao Tome And Principe',
		alpha2_code: 'ST',
		is_visible: true,
	},
	{
		id: 185,
		name: 'Saudi Arabia',
		alpha2_code: 'SA',
		is_visible: true,
	},
	{
		id: 186,
		name: 'Senegal',
		alpha2_code: 'SN',
		is_visible: true,
	},
	{
		id: 187,
		name: 'Seychelles',
		alpha2_code: 'SC',
		is_visible: true,
	},
	{
		id: 188,
		name: 'Sierra Leone',
		alpha2_code: 'SL',
		is_visible: true,
	},
	{
		id: 189,
		name: 'Singapore',
		alpha2_code: 'SG',
		is_visible: true,
	},
	{
		id: 190,
		name: 'Slovakia',
		alpha2_code: 'SK',
		is_visible: true,
	},
	{
		id: 191,
		name: 'Slovenia',
		alpha2_code: 'SI',
		is_visible: true,
	},
	{
		id: 192,
		name: 'Solomon Islands',
		alpha2_code: 'SB',
		is_visible: true,
	},
	{
		id: 194,
		name: 'South Africa',
		alpha2_code: 'ZA',
		is_visible: true,
	},
	{
		id: 195,
		name: 'Spain',
		alpha2_code: 'ES',
		is_visible: true,
	},
	{
		id: 196,
		name: 'Sri Lanka',
		alpha2_code: 'LK',
		is_visible: true,
	},
	{
		id: 198,
		name: 'Suriname',
		alpha2_code: 'SR',
		is_visible: true,
	},
	{
		id: 200,
		name: 'Swaziland',
		alpha2_code: 'SZ',
		is_visible: true,
	},
	{
		id: 201,
		name: 'Sweden',
		alpha2_code: 'SE',
		is_visible: true,
	},
	{
		id: 202,
		name: 'Switzerland',
		alpha2_code: 'CH',
		is_visible: true,
	},
	{
		id: 204,
		name: 'Taiwan',
		alpha2_code: 'TW',
		is_visible: true,
	},
	{
		id: 205,
		name: 'Tajikistan',
		alpha2_code: 'TJ',
		is_visible: true,
	},
	{
		id: 206,
		name: 'Tanzania, United Republic of',
		alpha2_code: 'TZ',
		is_visible: true,
	},
	{
		id: 207,
		name: 'Thailand',
		alpha2_code: 'TH',
		is_visible: true,
	},
	{
		id: 211,
		name: 'Trinidad And Tobago',
		alpha2_code: 'TT',
		is_visible: true,
	},
	{
		id: 212,
		name: 'Tunisia',
		alpha2_code: 'TN',
		is_visible: true,
	},
	{
		id: 213,
		name: 'Turkey',
		alpha2_code: 'TR',
		is_visible: true,
	},
	{
		id: 214,
		name: 'Turkmenistan',
		alpha2_code: 'TM',
		is_visible: true,
	},
	{
		id: 215,
		name: 'Turks and Caicos Islands',
		alpha2_code: 'TC',
		is_visible: true,
	},
	{
		id: 217,
		name: 'Uganda',
		alpha2_code: 'UG',
		is_visible: true,
	},
	{
		id: 218,
		name: 'Ukraine',
		alpha2_code: 'UA',
		is_visible: true,
	},
	{
		id: 219,
		name: 'United Arab Emirates',
		alpha2_code: 'AE',
		is_visible: true,
	},
	{
		id: 220,
		name: 'United Kingdom',
		alpha2_code: 'GB',
		is_visible: true,
	},
	{
		id: 221,
		name: 'United States',
		alpha2_code: 'US',
		is_visible: true,
	},
	{
		id: 223,
		name: 'Uruguay',
		alpha2_code: 'UY',
		is_visible: true,
	},
	{
		id: 224,
		name: 'Uzbekistan',
		alpha2_code: 'UZ',
		is_visible: true,
	},
	{
		id: 227,
		name: 'Venezuela',
		alpha2_code: 'VE',
		is_visible: true,
	},
	{
		id: 228,
		name: 'Vietnam',
		alpha2_code: 'VN',
		is_visible: true,
	},
	{
		id: 229,
		name: 'Virgin Islands, British',
		alpha2_code: 'VG',
		is_visible: true,
	},
	{
		id: 233,
		name: 'Yemen',
		alpha2_code: 'YE',
		is_visible: true,
	},
	{
		id: 237,
		name: 'Zimbabwe',
		alpha2_code: 'ZW',
		is_visible: true,
	},
	{
		id: 239,
		name: 'Serbia',
		alpha2_code: 'RS',
		is_visible: true,
	},
	{
		id: 247,
		name: 'Laos',
		alpha2_code: 'LA',
		is_visible: true,
	},
];
