import React from 'react';
import PropTypes from 'prop-types';

import './Avatar.css';

function Avatar({ src, alt }) {
	return (
		<div className="avatar">
			{src && <img src={src} alt={alt} />}
		</div>
	);
}

Avatar.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
};

Avatar.defaultProps = {
	src: null,
	alt: null,
};

export default React.memo(Avatar);
// TODO: add on error function
