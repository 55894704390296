import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from '../../../components/Table';

import MediaColumns from '../MediaListColumns';

export default function RenderMedias({
	original,
	medias,
	stats,
	fetchingInfluencers,
	resized,
}) {
	const memoizedData = useMemo(() => medias.filter((m) => m.source_id === original.id && m.type === 'link'), [medias, original]);
	const memoizedColumns = useMemo(() => MediaColumns({ clickStats: stats.medias }), [stats]);
	return (
		<div className="medias">
			<Table
				data={memoizedData}
				columns={memoizedColumns}
				pageSize={1000}
				PadRowComponent={() => null}
				sortable={false}
				defaultSortDesc
				showPageSizeOptions={false}
				showPageJump={false}
				showPagination={false}
				loading={fetchingInfluencers}
				resized={resized}
			/>
		</div>
	);
}

RenderMedias.propTypes = {
	original: PropTypes.shape(),
	medias: PropTypes.arrayOf(PropTypes.shape()),
	stats: PropTypes.shape(),
	fetchingInfluencers: PropTypes.bool,
};

RenderMedias.defaultProps = {
	original: {},
	medias: [],
	stats: {},
	fetchingInfluencers: false,
};
