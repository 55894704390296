import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Route,
	Link,
	useHistory,
	Redirect,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import findIndex from 'lodash/findIndex';
import {
	PageTitle,
	StepsNav,
	Modal,
	Alert,
	Loader,
} from 'interceptd-ui';

import Button from '../../components/Button';

import TrackingURLModal from '../Modals/TrackingURLModal';

import Step1 from './CStep1';
import Step3 from './Step3';
import Step4 from './Step4';

import Api from '../../services/api';
import useSilo from '../../services/use-silo';

import './styles/Create.css';

const ViewRoute = ({
	component: Component,
	path,
	exact,
	...rest
}) => (
	<Route
		path={path}
		exact={exact}
		render={(props) => <Component {...props} {...rest} />}
	/>
);

ViewRoute.propTypes = {
	component: PropTypes.elementType.isRequired,
	path: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
	]).isRequired,
	exact: PropTypes.bool,
};

ViewRoute.defaultProps = {
	exact: true,
};

const Steps = [
	{
		to: '',
		text: 'Select Influencers',
	},
	{
		to: 'detail',
		text: 'Campaign Detail',
	},
	{
		to: 'summary',
		text: 'Summary',
	},
];

const BASE_MEDIA = {
	landing_url: '',
};

const BASE_SOURCE = {
	cost: '',
	medias: [
		{
			...BASE_MEDIA,
		},
	],
};

export default function Create({ match }) {
	const history = useHistory();
	const { silo } = useSilo();
	const [seenFirst, setSeenFirst] = useState(false);
	const [creating, setCreating] = useState(false);
	const [created, setCreated] = useState(false);
	const [form, setForm] = useState({
		name: '',
		landing_url: '',
		mentions: [],
	});

	const [influencers, setInfluencers] = useState([]);
	const [sources, setSources] = useState([]);

	const [createdCampaign, setCreatedCampaign] = useState({});
	const [createdSources, setCreatedSources] = useState([]);
	const [createdMedias, setCreatedMedias] = useState([]);

	const [activeStep, setActiveStep] = useState(0);

	const { params: { page } } = match;

	const influencerList = silo?.data?.influencers || [];

	const maxLinkLimit = silo?.currentPlan?.limits?.story_limit ?? 0;
	const totalLinkCount = silo?.data?.medias.filter((m) => m.type === 'link').length;
	const storyLeft = maxLinkLimit - totalLinkCount;

	useEffect(() => {
		setSeenFirst(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setActiveStep(Steps.findIndex((step) => (page || '') === step.to));
	}, [page]);

	useEffect(() => {
		setSources((prevSources) => influencers.reduce((acc, cur) => {
			const newAcc = [...acc];
			const found = prevSources.find((source) => source.name === cur.name);
			if (found) {
				newAcc.push({
					...found,
					...cur,
				});
			} else {
				newAcc.push({
					...cur,
					...BASE_SOURCE,
				});
			}
			return newAcc;
		}, []));
	}, [influencers]);

	const handleInfluencerChange = (inf) => {
		const index = findIndex(influencers, ['username', inf.username]);
		const copy = influencers.slice();

		if (index === -1) {
			setInfluencers([
				...copy,
				{
					...inf,
					name: inf.username,
					fetching: false,

					valid: true,
					error: null,
					retry: 0,
				},
			]);
		} else {
			copy.splice(index, 1);
			setInfluencers(copy);
		}
	};

	const handleSourceChange = (name, key, value) => {
		setSources((prevSources) => (
			prevSources.map((source) => source.name === name ? ({
				...source,
				[key]: value,
			}) : source)
		));
	};

	const handleSourceRemove = (name) => {
		setSources((prevSources) => prevSources.filter((source) => source.name !== name));
	};

	const handleMediaChange = (name, index, key, value) => {
		setSources((prevSources) => (
			prevSources.map((source) => source.name === name ? ({
				...source,
				medias: source.medias.map((media, i) => i === index ? ({
					...media,
					[key]: value,
				}) : media),
			}) : source)
		));
	};

	const handleMediaAdd = (name) => {
		setSources((prevSources) => (
			prevSources.map((source) => source.name === name ? ({
				...source,
				medias: [
					...source.medias,
					{
						...BASE_MEDIA,
					},
				],
			}) : source)
		));
	};

	const handleMediaRemove = (name, index) => {
		setSources((prevSources) => (
			prevSources.map((source) => source.name === name ? ({
				...source,
				medias: source.medias.filter((_, i) => i !== index),
			}) : source)
		));
	};

	const handleFormChange = (key, value) => {
		setForm((prevForm) => ({
			...prevForm,
			[key]: value,
		}));
	};

	const isNextDisabled = (step) => {
		switch (step) {
		case '':
			return influencers.length === 0;
		case 'validate':
			return influencers
				.filter((influencer) => (
					!influencer.fetching
					&& influencer.data
					&& influencer.valid
				)).length === 0;
		case 'detail':
			return form.name === ''
				|| (form.landing_url === '' && form.mentions.length === 0);
		default:
			return false;
		}
	};

	const getValidUrl = (url = '') => {
		if (url === '') return '';

		let newUrl = url;
		newUrl = newUrl.trim().replace(/\s/g, '');

		if (/^(:\/\/)/.test(newUrl)) {
			return `https${newUrl}`;
		}
		if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
			return `https://${newUrl}`;
		}

		return newUrl;
	};

	const save = async () => {
		setCreating(true);
		try {
			// Add influencers
			const infsToAdd = influencers
				.filter((influencer) => (
					!influencer.fetching
					&& influencer.data
					&& influencer.valid
					&& influencer.id
				));
			await Api.addInfluencers({ influencer_ids: infsToAdd.map((i) => i.id) });

			// Create campaign
			const campaignResponse = await Api.createCampaign({
				name: form.name,
				landing_page_url: getValidUrl(form.landing_url) !== '' ? getValidUrl(form.landing_url) : ' ',
				cost: 0,
				mention: form.mentions,
			});
			const campaign = campaignResponse?.data?.data;

			if (campaign) {
				setCreatedCampaign(campaign);

				// Create sources
				for (const source of sources) {
					// Create source
					const sourceResponse = await Api.createSource({
						campaign_id: campaign.id,
						influencer_id: source.id,
						landing_page_url: getValidUrl(form.landing_url) !== '' ? getValidUrl(form.landing_url) : ' ',
						mention: form.mentions,
						cost: source.cost === '' ? 0 : +source.cost,
					});
					const createdSource = sourceResponse?.data.data;

					if (createdSource) {
						setCreatedSources((prevSources) => ([
							...prevSources,
							{
								...createdSource,
								data: source,
							},
						]));

						// Create medias
						for (const media of source.medias) {
							// Create media
							const landing_url = getValidUrl(media.landing_url !== '' ? media.landing_url : form.landing_url);
							if (landing_url !== '') {
								const mediaResponse = await Api.createMedia({
									campaign_id: campaign.id,
									source_id: createdSource.id,
									influencer_id: source.id,
									internal_type: 'link',
									type: 'link',
									landing_page_url: landing_url,
									cost: source.cost === '' ? 0 : +source.cost,
								});
								const createdMedia = mediaResponse?.data.data;

								setCreatedMedias((prevMedias) => ([
									...prevMedias,
									createdMedia,
								]));
							}
						}
					}
				}
				setCreated(true);
				toast.success('Campaign created');
			}

			setCreating(false);
		} catch (error) {
			setCreating(false);
			toast.error('Something went wrong');
		}
	};

	if (silo?.data?.loading) return <Loader />;
	if (influencerList.length === 0) return <Redirect to="/influencer-add" />;

	return (
		<div className="campaign-create">
			<div className="campaign-create-header">
				<PageTitle>
					<div className="campaign-create-header-inner">
						<PageTitle.Title>
							Campaign Create
							{' '}
							{(form.name && form.name !== '') && '-'}
							{' '}
							{form.name}
						</PageTitle.Title>
					</div>
				</PageTitle>
				<StepsNav active={activeStep} data-active={activeStep}>
					{(setProgressWidth) => Steps.map((step, index) => (
						<StepsNav.Step key={`step-${index}`} active={(page || '') === step.to} setProgressWidth={setProgressWidth}>
							<Link to={`/create${step.to === '' ? '' : '/'}${step.to}`}>{step.text}</Link>
						</StepsNav.Step>
					))}
				</StepsNav>
			</div>

			<ViewRoute
				exact
				path="/create"
				component={Step1}
				seenFirst={seenFirst}
				selectedInfluencers={influencers}
				influencers={influencerList}
				setInfluencers={setInfluencers}
				onChange={handleInfluencerChange}
			/>

			<ViewRoute
				exact
				path="/create/detail"
				component={Step3}
				seenFirst={seenFirst}
				sources={sources}
				onSourceChange={handleSourceChange}
				onSourceRemove={handleSourceRemove}
				onMediaChange={handleMediaChange}
				onMediaAdd={handleMediaAdd}
				onMediaRemove={handleMediaRemove}
				form={form}
				onFormChange={handleFormChange}
			/>

			<ViewRoute
				exact
				path="/create/summary"
				component={Step4}
				seenFirst={seenFirst}
				sources={sources}
				form={form}
				getValidUrl={getValidUrl}
			/>
			<div className="campaign-create-alert">
				<Alert showDot={false} showTitle={false}>
					{`You have ${storyLeft} stor${storyLeft === 1 ? 'y' : 'ies'} left. You can check other subscription plans from Payment settings.`}
				</Alert>
			</div>
			{storyLeft > 0 ? (
				<div className="campaign-create-buttons">
					{activeStep !== 0 && (
						<Button
							mini
							disabled={creating}
							bgColor="transparent"
							onClick={() => history.push(`/create${Steps[activeStep - 1].to === '' ? '' : '/'}${Steps[activeStep - 1].to}`)}
						>
							Prev
						</Button>
					)}
					<Button
						mini
						bgColor="shade"
						loading={creating}
						disabled={isNextDisabled(Steps[activeStep].to)}
						onClick={() => (activeStep === Steps.length - 1
							? save()
							: history.push(`/create${Steps[activeStep + 1].to === '' ? '' : '/'}${Steps[activeStep + 1].to}`))}
					>
						{activeStep === 0 ? 'Start' : activeStep === Steps.length - 1 ? 'Done' : 'Next'}
					</Button>
				</div>
			) : (
				<div className="campaign-create-buttons">
					<Button
						mini
						bgColor="shade"
						to="/account/plans"
					>
						Plans
					</Button>
				</div>
			)}

			<Modal
				open={created}
				icon={null}
				title="Campaign Tracking Links"
				acceptProps={{
					style: {
						display: 'none',
					},
				}}
				onCancel={() => {
					window.location.href = '/campaign-list';
				}}
				defaultCancelText="Close"
			>
				<TrackingURLModal
					redirectTo="/campaign-list"
					refresh
					campaign={createdCampaign}
					sources={(
						createdSources
							.filter((source) => source.campaign_id === createdCampaign.id)
							.map((source) => ({
								...source,
								medias: createdMedias.filter((media) => media.source_id === source.id),
							}))
					)}
				/>
			</Modal>
		</div>
	);
}

Create.propTypes = {
	match: PropTypes.shape().isRequired,
};
