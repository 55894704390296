import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import {
	Icon,
	Copy,
} from 'interceptd-ui';

import SummaryBox from '../../components/SummaryBox';
import SummaryCell from '../../components/SummaryCell';

import './styles/TrackingURLModal.css';

function TrackingURLModal({
	campaign,
	sources,
	redirectTo,
	refresh,
}) {
	if (redirectTo && sources.every((source) => source.medias.length === 0)) {
		if (refresh) {
			window.location.href = redirectTo;
		}
		return !refresh ? <Redirect to={redirectTo} /> : null;
	}

	return (
		<React.Fragment>
			<div className="tracking-url-modal-header">
				<div className="tracking-url-modal-title">{campaign.name}</div>
				<div className="tracking-url-modal-url">{campaign.landing_page_url}</div>
			</div>
			<div className="tracking-url-modal-content">
				{sources.map((source, index) => (
					<SummaryBox key={index}>
						<SummaryCell
							isBlock
							title="Username"
							renderBefore={() => (
								<div className="create-influencer-box-image">
									<img
										src={source?.data?.data?.profile_pic_url}
										alt={source?.data?.data?.username}
									/>
								</div>
							)}
						>
							{source?.data?.data?.username}
						</SummaryCell>
						{source.medias
							.filter((media) => media.type === 'link')
							.length > 0 && (
							<SummaryCell
								isBlock
								title="Tracking Links"
								className="source-landing-urls"
							>
								{source.medias
									.filter((media) => media.type === 'link')
									.map((media, i) => (
										<div key={i} className="tracking-url-modal-wrapper">
											<div className="tracking-url-item">
												<span className="tracking-modal-url-text">https://trk.interceptd.com/inf-click?med={media.external_id}</span>
												<Copy
													value={`https://trk.interceptd.com/inf-click?med=${media.external_id}`}
													onCopy={() => toast.success('Link copied')}
												>
													<Icon i="copy" size={15} /> Copy Link
												</Copy>
											</div>
											<div className="tracking-url-item">
												<div className="tracking-modal-landing-page-url-wrapper">
													<Icon i="corner-down-right" size={15} />
													<span className="tracking-modal-url-text">{media.landing_page_url}</span>
												</div>
												<Copy
													value={media.landing_page_url}
													onCopy={() => toast.success('Link copied')}
												>
													<Icon i="copy" size={15} /> Copy Link
												</Copy>
											</div>
										</div>
									))}
							</SummaryCell>
						)}
					</SummaryBox>
				))}
			</div>
		</React.Fragment>
	);
}

TrackingURLModal.propTypes = {
	campaign: PropTypes.shape().isRequired,
	sources: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	redirectTo: PropTypes.string,
	refresh: PropTypes.bool,
};

TrackingURLModal.defaultProps = {
	redirectTo: null,
	refresh: false,
};

export default React.memo(
	TrackingURLModal,
	(prevProps, nextProps) => isEqual(prevProps, nextProps),
);
