import useLocal from './use-local';

export default function useAuth() {
	const { store, resetStore } = useLocal();

	const token = store?.token;
	const isAuth = !!token;
	const logout = () => {
		resetStore();
		window.location.href = '/';
	};

	return { isAuth, token, logout };
}
