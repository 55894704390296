/* eslint-disable react/prop-types */
import React from 'react';
import Humanize from 'humanize-plus';
import { Info, Tooltip } from 'interceptd-ui';

import StatsCell from '../../components/StatsCell';
import Avatar from '../../components/Avatar';
import Calculating from '../../components/Calculating';

import {
	engagementRateDescription,
	estimatedReachDescription,
	authenticEngagementRateDescription,
} from '../../constants/descriptions';

const Columns = ({
	clickStats,
	medias,
	isSource,
	onExpandedChange,
}) => [
	{
		Header: 'expander',
		Expander: () => null,
		expander: true,
		show: false,
	},
	{
		Header: 'Influencer Name',
		id: 'username',
		accessor: 'username',
		cast: 'string',
		minWidth: 150,
		style: {
			overflow: 'visible',
		},
		sticky: true,
		stickyPosition: 'left',
		Cell: ({
			value,
			original,
			viewIndex,
			isExpanded,
		}) => (
			<React.Fragment>
				{onExpandedChange && medias.filter((m) => m.source_id === original.id && m.type === 'link').length > 0 ? (
					<div className={`rt-expander ${isExpanded ? '-open' : ''}`} onClick={() => onExpandedChange(viewIndex)} />
				) : <span className="spacer" />}
				<div className="influencer-col">
					<Avatar src={original?.influencer?.data?.profile_pic_url} alt={value} />
					<div className="influencer-col-info-wrapper">
						<span to={`/influencer/${original.influencer?.type}/${original.influencer?.username}`} className="influencer-col-name">{`@${original.influencer?.username || 'deleted'}`}</span>
						<span className="influencer-col-info">{original?.influencer?.data?.follower_count ? Humanize.compactInteger(original?.influencer?.data?.follower_count, 0).toUpperCase() : ''}</span>
					</div>
				</div>
			</React.Fragment>
		),
	},
	{
		Header: 'Clicks',
		id: 'click',
		cast: 'number',
		sortable: true,
		minWidth: 130,
		accessor: (val) => isSource
			? clickStats[val.id]?.total || 0
			: clickStats[val.influencer_id]?.total,
		Cell: ({ original }) => (
			<StatsCell
				data={isSource ? clickStats[original.id] : clickStats[original.influencer_id]}
			/>
		),
	},
	{
		Header: 'Links',
		id: 'links',
		cast: 'number',
		sortable: true,
		minWidth: 90,
		accessor: (val) => medias.reduce((acc, curr) => {
			let res = acc;
			if (curr.influencer_id === val.influencer_id && curr.type === 'link') res += 1;
			return res;
		}, 0),
	},
	{
		Header: 'Posts',
		id: 'posts',
		cast: 'number',
		sortable: true,
		minWidth: 90,
		accessor: (val) => medias.reduce((acc, curr) => {
			let res = acc;
			if (curr.influencer_id === val.influencer_id && curr.type !== 'link') res += 1;
			return res;
		}, 0),
	},
	{
		Header: () => (
			<div className="column-header-with-info">
				<Info tooltipProps={{ position: 'left' }}>{engagementRateDescription}</Info>
				<span>Engagement Rate</span>
			</div>
		),
		id: 'engagement',
		cast: 'number',
		sortable: true,
		accessor: 'engagement',
		minWidth: 140,
		Cell: ({ value, original }) => (
			<div style={{ display: 'flex' }}>
				<span>{value === 'N/A' ? <Tooltip position="left" tooltip="Metrics will be calculated once posts with your hashtags/mentions are live.">N/A</Tooltip> : value}</span>
				<span className="influencer-engagement-info">({original.influencer.engagement}%)</span>
			</div>
		),
	},
	{
		Header: () => (
			<div className="column-header-with-info">
				<Info tooltipProps={{ position: 'left' }}>{authenticEngagementRateDescription}</Info>
				<span>Authentic Engagement Rate</span>
			</div>
		),
		id: 'authenticEngagementRate',
		cast: 'number',
		sortable: true,
		accessor: 'authenticEngagementRate',
		minWidth: 163,
		Cell: ({ value, original }) => {
			const influencerId = original.influencer_id;
			const totalPost = medias.reduce((acc, curr) => {
				let res = acc;
				if (curr.influencer_id === influencerId && curr.type !== 'link') res += 1;
				return res;
			}, 0);

			if (totalPost === 0) return <Tooltip position="left" tooltip="Metrics will be calculated once posts with your hashtags/mentions are live.">N/A</Tooltip>;

			return value
				? (
					<div>
						<span>{value.toFixed(2)}%</span>
						<span className="influencer-engagement-info">({original.influencer.authenticEngagementRate}%)</span>
					</div>
				) : <Calculating />;
		},
	},
	{
		Header: 'CPE',
		id: 'cpe',
		accessor: 'cpe',
		cast: 'number',
		sortable: true,
		minWidth: 100,
	},
	{
		Header: () => (
			<div className="column-header-with-info">
				<Info tooltipProps={{ position: 'left' }}>{estimatedReachDescription}</Info>
				<span>Potential Reach</span>
			</div>
		),
		id: 'estimatedReach',
		cast: 'number',
		sortable: true,
		accessor: 'estimatedReach',
		minWidth: 120,
		Cell: ({ value, original }) => {
			const influencerId = original.influencer_id;
			const totalPost = medias.reduce((acc, curr) => {
				let res = acc;
				if (curr.influencer_id === influencerId && curr.type !== 'link') res += 1;
				return res;
			}, 0);

			if (totalPost === 0) return <Tooltip position="left" tooltip="Metrics will be calculated once posts with your hashtags/mentions are live.">N/A</Tooltip>;

			return value === <Tooltip position="left" tooltip="Metrics will be calculated once posts with your hashtags/mentions are live.">N/A</Tooltip> ? <Calculating /> : value;
		},
	},
];

export default Columns;
