import React, { Fragment } from 'react';

import Header from '../Auth/components/Header';

import data from './GlossaryData';

import './Glossary.css';

export default function Glossary() {
	return (
		<Fragment>
			<Header />
			<div className="glossary-wrapper">
				<div className="glossary">
					<h3 className="glossary-title">Glossary</h3>
					{Object.keys(data).map((title) => (
						<React.Fragment key={title}>
							<h4 className="glossary-content-title">{title}</h4>
							{Object.keys(data[title]).map((content) => (
								<div className="glossary-content" key={content}>
									<span className="glossary-content-name">{content}:</span>
									<span className="glossary-content-body">{data[title][content]}</span>
								</div>
							))}
						</React.Fragment>
					))}
				</div>
			</div>
		</Fragment>
	);
}
