import React from 'react';
import { Sticky } from 'react-sticky';
import isEqual from 'lodash/isEqual';

const StickyThead = (props) => {
	const { className, style: compStyle, ...rest } = props;
	return (
		<Sticky>
			{({ style, isSticky }) => (
				<div
					className={`rt-thead ${className} ${isSticky ? '-sticked' : ''}`}
					style={{ ...style, ...compStyle }}
					{...rest}
				/>
			)}
		</Sticky>
	);
};

export default React.memo(StickyThead, (prevProps, nextProps) => isEqual(prevProps, nextProps));
