import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../components/Button';

import './styles/NotFound.css';

export default function NotFound() {
	return (
		<div className="notfound">
			<div className="four-oh-four">
				<span className="four">
					<span className="four-horizontal" />
					<span className="four-vertical" />
				</span>
				<span className="oh" />
				<span className="four">
					<span className="four-horizontal" />
					<span className="four-vertical" />
				</span>
			</div>
			<div className="notfound-text">
				<div className="notfound-title">This page doesn't exist</div>
				<Button component={Link} to="/">Go to Homepage</Button>
			</div>
		</div>
	);
}
