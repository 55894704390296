import React from 'react';
import PropTypes from 'prop-types';
import Humanize from 'humanize-plus';
import isEqual from 'lodash/isEqual';
import { Tooltip } from 'interceptd-ui';

import ScoreBar from '../ScoreBar';
import Calculating from '../Calculating';

import './StatsBox.css';

function StatsBox({
	data,
	hideHealthScore,
	showAuthEngRate,
	authEngRate,
	clickDistHover,
}) {
	const {
		totalSource,
		totalMedia = {},
		totalClicks = {},
		totalEngagement,
	} = data;

	const score = totalClicks.total > 0
		? (!totalClicks?.flags
			? 0
			: (totalClicks?.flags / totalClicks?.total) * 100)
		: null;

	return (
		<div className="stats-box">
			{!hideHealthScore && (
				<div className="stats-box-data stats-box-data-min-width">
					<span className="stats-box-data-title">Health</span>
					<ScoreBar value={score} />
				</div>
			)}
			<div className="stats-box-data">
				<span className="stats-box-data-title">Influencers</span>
				<span className="stats-box-data-value">{totalSource}</span>
			</div>
			<div className="stats-box-data">
				<span className="stats-box-data-title">Links</span>
				<span className="stats-box-data-value">{totalMedia?.link || 0}</span>
				{/* <div className="stats-box-data-footer">
					<span className="stats-box-data-footer-value">{totalMedia?.activeLink || 0}</span>
					<span className="stats-box-data-footer-text">active</span>
				</div> */}
			</div>
			<div className="stats-box-data">
				<span className="stats-box-data-title">Clicks</span>
				<span className="stats-box-data-value">{totalClicks?.total || 0}</span>
				<div className="stats-box-data-footer">
					<span className="stats-box-data-footer-value clean">{totalClicks?.clean || 0}</span>
					<span className="stats-box-data-footer-text">clean</span>
					<span className="stats-box-data-footer-value suspicious">
						<Tooltip tooltip={clickDistHover || null}>
							{totalClicks?.flags || 0}
						</Tooltip>
					</span>
					<span className="stats-box-data-footer-text">suspicious</span>
				</div>
			</div>
			<div className="stats-box-data">
				<span className="stats-box-data-title">Post</span>
				<span className="stats-box-data-value">{totalMedia?.post || 0}</span>
				{/* <div className="stats-box-data-footer">
					<span className="stats-box-data-footer-value">{totalMedia?.activeLink || 0}</span>
					<span className="stats-box-data-footer-text">active</span>
				</div> */}
			</div>
			{totalEngagement && (
				<div className="stats-box-data">
					<span className="stats-box-data-title">Engagement Rate</span>
					<span className="stats-box-data-value">{totalEngagement?.rate ? `${totalEngagement?.rate}%` : 'N/A'}</span>
					<div className="stats-box-data-footer">
						<span className="stats-box-data-footer-value clean">{Humanize.compactInteger(totalEngagement?.comment || 0)}</span>
						<span className="stats-box-data-footer-text">comment</span>
						<span className="stats-box-data-footer-value clean">{Humanize.compactInteger(totalEngagement?.like || 0)}</span>
						<span className="stats-box-data-footer-text">likes</span>
					</div>
				</div>
			)}
			{showAuthEngRate && (
				<div className="stats-box-data stats-box-data-min-width">
					<span className="stats-box-data-title">Authentic Engagement Rate</span>
					<span className="stats-box-data-value">{typeof authEngRate === 'number' && !Number.isNaN(authEngRate) ? `${authEngRate.toFixed(2)}%` : <Calculating />}</span>
				</div>
			)}
		</div>
	);
}

StatsBox.propTypes = {
	data: PropTypes.shape(),
	authEngRate: PropTypes.number,
	hideHealthScore: PropTypes.bool,
	showAuthEngRate: PropTypes.bool,
	clickDistHover: PropTypes.shape(),
};

StatsBox.defaultProps = {
	hideHealthScore: false,
	showAuthEngRate: false,
	authEngRate: null,
	clickDistHover: null,
	data: {
		totalSource: {},
		totalMedia: {},
		totalClicks: {},
		totalEngagement: {},
	},
};

export default React.memo(
	StatsBox,
	(prevProps, nextProps) => isEqual(prevProps.data, nextProps.data),
);
