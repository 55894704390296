import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon } from 'interceptd-ui';

import Button from '../../components/Button';

import Sidebar from './components/Sidebar';
import Header from './components/Header';

import Api from '../../services/api';
import useLocal from '../../services/use-local';

import './styles/Login.css';

export default function Verify({ match, location }) {
	const [sending, setSending] = React.useState(false);
	const [sent, setSent] = React.useState(false);
	const [redirect, setRedirect] = React.useState(false);
	const [to, setTo] = React.useState('/login');
	const { setStoreItem, resetStore } = useLocal();

	React.useEffect(() => {
		async function verifyUser() {
			resetStore();
			Api.setToken();

			if (match?.params?.code) {
				const verify = await Api.verifyUser(match.params.code);
				if (verify?.data?.data?.token) {
					setStoreItem('login_as', false);
					setStoreItem('token', verify.data.data.token);
					Api.setToken(verify.data.data.token);
					setTo('/');
					setRedirect(true);
				} else {
					toast.success('Email is verified. You can sign in now!');
					setRedirect(true);
				}
			}
		}
		verifyUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resendVerifyEmail = async () => {
		const { state } = location;

		setSending(true);
		try {
			await Api.resendVerifyEmail({ email: state.email });
			setSending(false);
			setSent(true);
		} catch (response) {
			setSending(false);
		}
	};

	const { state } = location;

	if (redirect) {
		return (
			<Redirect to={{
				pathname: to,
				state: {
					referrer: '/verify',
					redirectTo: '/',
				},
			}}
			/>
		);
	}

	return (
		<div className="login">
			<Helmet>
				<title>Influencer Dashboard - Verify</title>
			</Helmet>
			<div className="login-wrapper">
				<Sidebar />
				<div className="login-right">
					<Header />
					<div className="verify-wrapper">
						{match.params.code ? (
							<div className="verify">
								<Icon i="activity" size={45} />
								<h1>Verifing your email address...</h1>
							</div>
						) : (
							<div className="verify">
								<Icon i="mail" size={45} />
								<h1>Verify Email Address</h1>
								<p>
									Look for the verification email in your inbox and click the link in that email.
									{' '}
									A confirmation message will appear in your web browser.
								</p>
								<p>
									Didn't get the email?
									{' '}
									Check your spam folder to make sure it didn't end up there
								</p>
								{state && state.email && (
									<Button block onClick={resendVerifyEmail} loading={sending} disabled={sent}>{sent ? 'Sent' : 'Send again'}</Button>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

Verify.propTypes = {
	match: PropTypes.shape().isRequired,
	location: PropTypes.shape().isRequired,
};
