import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Humanize from 'humanize-plus';
import { Box, Icon } from 'interceptd-ui';
import find from 'lodash/find';

import './styles/Step2.css';

const WARNING = 'The account you are adding is a private account. For private accounts you can’t see the full metrics and calculations on dashboard.';

export default function Step2({
	seenFirst,
	influencers,
	selectedInfluencers,
	setInfluencers,
	onChange,
}) {
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if (!seenFirst) {
			if (location.pathname.indexOf('influencer-add') > -1) {
				history.push('/influencer-add');
			} else {
				history.push('/create');
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seenFirst]);

	useEffect(() => {
		setInfluencers((prevState) => [...prevState]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="campaign-create-inner">
			{influencers.length > 0 && (
				<Box
					className="valid-influencers"
					title={`Influencers (${influencers.length})`}
				>
					{influencers.map((influencer) => {
						const username = (influencer.data?.username || influencer.name).replace('@', '');
						const isSelected = find(selectedInfluencers, ['username', username]);
						return (
							<div className={`create-influencer-box selectable ${isSelected ? 'selected' : ''} ${influencer?.data?.is_private ? 'has-error' : ''}`} onClick={() => onChange(influencer)}>
								<div className="create-influencer-box-image">
									<img src={influencer.data?.profile_pic_url} alt={username} />
								</div>
								<div className="create-influencer-box-info">
									<div className="create-influencer-box-info-label">Username</div>
									<div className="create-influencer-box-info-value">@{username}</div>
								</div>
								<div className="create-influencer-box-followers">
									{Humanize.compactInteger(influencer.data?.follower_count ?? 0, 1)}
								</div>
								{influencer?.data?.is_private && (
									<div className="create-influencer-box-error is-warning">
										{WARNING}
									</div>
								)}
								{isSelected && (
									<span className="selected-influencer-icon">
										<Icon i="check" size="15" />
									</span>
								)}
							</div>
						);
					})}
				</Box>
			)}
		</div>
	);
}

Step2.propTypes = {
	seenFirst: PropTypes.bool.isRequired,
	influencers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	setInfluencers: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};
