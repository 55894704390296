import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button as InterceptdButton } from 'interceptd-ui';

import './Button.css';

function Button({ to, ...rest }) {
	if (to) {
		return <InterceptdButton component={Link} to={to} {...rest} />;
	}
	return <InterceptdButton {...rest} />;
}

Button.propTypes = {
	to: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape(),
	]),
};

Button.defaultProps = {
	to: null,
};

export default React.memo(Button);
