import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Humanize from 'humanize-plus';
import { Icon } from 'interceptd-ui';

import Api from '../../../../services/api';

import './Influencer.css';

const NOT_FOUND = 'NOT_FOUND';
const NOT_INFLUENCER = 'NOT_INFLUENCER';
const PRIVATE_INFLUENCER = 'PRIVATE_INFLUENCER';
const ERRORS = {
	[NOT_FOUND]: 'Account doesn\'t exist',
	[NOT_INFLUENCER]: 'Account you are willing to add has less than 1000 followers. You can only add influencers with at least 1000 followers.',
};
const WARNINGS = {
	[PRIVATE_INFLUENCER]: 'The account you are adding is a private account. For private accounts you can’t see the full metrics and calculations on dashboard.',
};

export default function Influencer({ influencer, onChange }) {
	useEffect(() => {
		const fetchInfluencers = async () => {
			if (!influencer.data && !influencer.fetching && influencer.retry < 2) {
				onChange({
					...influencer,
					fetching: true,
				});
				try {
					const response = await Api.getInfluencer({
						type: 'instagram',
						username: influencer.name.replace('@', ''),
					});
					onChange({
						...influencer,
						id: response.data.data.id,
						fetching: false,
						data: response.data.data.data,
						valid: response.data.code === 'OK',
						error: response.data.code !== 'OK' ? NOT_INFLUENCER : null,
						warning: response.data.code === 'OK' && response.data.data.data.is_private ? PRIVATE_INFLUENCER : null,
						retry: 0,
					});
				} catch (error) {
					onChange({
						...influencer,
						fetching: false,
						valid: false,
						error: NOT_FOUND,
						retry: influencer.retry + 1,
					});
				}
			}
		};
		fetchInfluencers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [influencer]);

	const username = (influencer.data?.username || influencer.name).replace('@', '');

	return (
		<div className={`create-influencer-box ${!influencer.valid || influencer.warning ? 'has-error' : ''}`}>
			<div className="create-influencer-box-image">
				{(!influencer.fetching && influencer.data?.profile_pic_url) ? (
					<img src={influencer.data?.profile_pic_url} alt={username} />
				) : (!influencer.fetching && !influencer.data?.profile_pic_url) ? (
					<div className="create-influencer-box-image-not-found">
						<Icon i="slash" size={18} />
					</div>
				) : (
					<div className="create-influencer-box-image-fetching" />
				)}
			</div>
			<div className="create-influencer-box-info">
				<div className="create-influencer-box-info-label">Username</div>
				<div className="create-influencer-box-info-value">@{username}</div>
			</div>
			<div className={`create-influencer-box-followers ${!influencer.valid || influencer.warning ? 'is-error' : ''}`}>
				{Humanize.compactInteger(influencer.data?.follower_count ?? 0, 1)}
			</div>
			{influencer.error && (
				<div className="create-influencer-box-error">
					{ERRORS[influencer.error]}
				</div>
			)}
			{influencer.warning && (
				<div className="create-influencer-box-error is-warning">
					{WARNINGS[influencer.warning]}
				</div>
			)}
		</div>
	);
}

Influencer.propTypes = {
	influencer: PropTypes.shape({
		name: PropTypes.string,
		fetching: PropTypes.bool,
		data: PropTypes.shape(),
		valid: PropTypes.bool,
		error: PropTypes.string,
		warning: PropTypes.string,
		retry: PropTypes.number,
	}).isRequired,
	onChange: PropTypes.func,
};

Influencer.defaultProps = {
	onChange: () => null,
};
