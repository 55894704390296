import React from 'react';

import Logo from '../../../../assets/logo.png';

import './Header.css';

export default function Header() {
	return (
		<header className="login-header">
			<img src={Logo} alt="Interceptd" />
		</header>
	);
}
