import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from 'interceptd-ui';
import { misc } from 'interceptd-ui/dist/utils';

import Button from '../../components/Button';

import Sidebar from './components/Sidebar';
import Header from './components/Header';

import Api from '../../services/api';
import useLocal from '../../services/use-local';

import './styles/Login.css';

export default function Login({ location }) {
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [emailError, setEmailError] = React.useState();
	const [emailTouched, setEmailTouched] = React.useState(false);
	const [passwordError, setPasswordError] = React.useState();
	const [passwordTouched, setPasswordTouched] = React.useState(false);
	const [fetching, setFetching] = React.useState();
	const [redirect, setRedirect] = React.useState(false);
	const [to, setTo] = React.useState('/');
	const { setStoreItem, resetStore } = useLocal();

	React.useEffect(() => {
		resetStore();
		Api.setToken();

		if (location?.state?.from) {
			setTo(location?.state?.from);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	React.useEffect(() => {
		setEmailError((emailTouched && email === '' ? 'Email can\'t be blank' : emailTouched && !misc.validateEmail(email)) ? 'Email is not valid' : null);
		setPasswordError(passwordTouched && password === '' ? 'Password can\'t be blank' : null);
	}, [email, emailTouched, password, passwordTouched]);

	const isRoleValid = (roles) => {
		const acceptedRoles = [
			'ROLE_ACCOUNT_MANAGER',
			'ROLE_CUSTOMER',
		];
		return roles.some((r) => acceptedRoles.includes(r));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (fetching || emailError || passwordError) return false;

		setFetching(true);
		try {
			const login = await Api.login({ email, password });
			if (!isRoleValid(login.data.roles)) {
				throw Error({
					data: {
						error: {
							message: 'You don\'t have required authorization to login',
						},
					},
				});
			}

			setStoreItem('login_as', false);
			setStoreItem('token', login.data.token);
			Api.setToken(login.data.token);

			setFetching(false);
			setRedirect(true);
		} catch ({ response }) {
			setFetching(false);
			if (response?.data?.error?.code === 'UserNotVerified') {
				setRedirect(true);
				setTo({
					pathname: '/verify',
					state: { email },
				});
			} else if (
				typeof response?.data?.error?.message === 'object'
				&& response?.data?.error?.message !== null
			) {
				toast.error('Something went wrong.');
			} else {
				toast.error(response?.data?.error?.message || 'Something went wrong.');
			}
		}

		return false;
	};

	const handleEmail = (event) => {
		setEmail(event.target.value);
		setEmailTouched(true);
	};

	const handlePassword = (event) => {
		setPassword(event.target.value);
		setPasswordTouched(true);
	};

	if (redirect) return <Redirect to={to} />;

	return (
		<div className="login">
			<Helmet>
				<title>Influencer Dashboard - Login</title>
			</Helmet>
			<div className="login-wrapper">
				<Sidebar />
				<div className="login-right">
					<Header />
					<form className="login-form" onSubmit={handleSubmit}>
						<div className="login-title">
							<h1>Sign In</h1>
						</div>
						<div className="login-form-inner">
							<Input
								type="email"
								name="email"
								placeholder="john@doe.com"
								label="Email Address"
								value={email}
								error={emailError}
								onChange={handleEmail}
							/>
							<Input
								type="password"
								name="password"
								label="Password"
								value={password}
								error={passwordError}
								onChange={handlePassword}
							/>
							<Button type="submit" block loading={fetching}>Sign In</Button>
							<Button to="/signup" block bgColor="transparent">Create an account</Button>
							<Link to="/forgot-password" className="forgot-password">Did you forget your password?</Link>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

Login.propTypes = {
	location: PropTypes.shape().isRequired,
};
