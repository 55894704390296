import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Icon } from 'interceptd-ui';

import Influencer from './components/Influencer';

import './styles/Step2.css';

export default function Step2({
	seenFirst,
	influencers,
	setInfluencers,
	onChange,
}) {
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if (!seenFirst) {
			if (location.pathname.indexOf('influencer-add') > -1) {
				history.push('/influencer-add');
			} else {
				history.push('/create');
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seenFirst]);

	useEffect(() => {
		setInfluencers((prevState) => [...prevState]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const valids = influencers
		.filter((influencer) => !influencer.fetching && influencer.data && influencer.valid);
	const notValids = influencers
		.filter((influencer) => !influencer.fetching && (!influencer.data || !influencer.valid));
	const checking = influencers
		.filter((influencer) => influencer.fetching);

	return (
		<div className="campaign-create-inner">
			{valids.length > 0 && (
				<Box
					className="valid-influencers"
					title={`Confirmed Influencers (${valids.length})`}
					right={(
						<Icon style={{ color: 'var(--green)' }} i="check" size={20} />
					)}
				>
					{valids.map((influencer) => (
						<Influencer key={influencer.name} influencer={influencer} onChange={onChange} />
					))}
				</Box>
			)}
			{notValids.length > 0 && (
				<Box
					className="not-valid-influencers"
					title={`Unconfirmed Accounts (${notValids.length})`}
				>
					{notValids.map((influencer) => (
						<Influencer key={influencer.name} influencer={influencer} onChange={onChange} />
					))}
				</Box>
			)}
			{checking.length > 0 && (
				<Box
					className="not-valid-influencers"
					title={`Checking... (${checking.length})`}
				>
					{checking.map((influencer) => (
						<Influencer key={influencer.name} influencer={influencer} onChange={onChange} />
					))}
				</Box>
			)}
			<div style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				fontSize: 14,
				color: 'var(--darkgray)',
				marginBottom: -10,
			}}
			>
				<Icon i="info" size={18} style={{ marginRight: 10 }} />
				<span>You will continue with confirmed influencers only.</span>
			</div>
		</div>
	);
}

Step2.propTypes = {
	seenFirst: PropTypes.bool.isRequired,
	influencers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	setInfluencers: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};
