import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Humanize from 'humanize-plus';

import SummaryBox from '../../components/SummaryBox';
import SummaryCell from '../../components/SummaryCell';

import './styles/Step4.css';

export default function Step4({
	seenFirst,
	sources,
	form,
	getValidUrl,
}) {
	const history = useHistory();

	useEffect(() => {
		if (!seenFirst) {
			history.push('/create');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seenFirst]);

	return (
		<div className="campaign-create-inner">
			<SummaryBox title="Campaign Detail" editUrl="/create/detail">
				<SummaryCell title="Campaign Name" isBlock>
					{form.name}
				</SummaryCell>
				<SummaryCell title="Campaign Landing URL" isBlock className="source-landing-urls">
					<div>{getValidUrl(form.landing_url) !== '' ? getValidUrl(form.landing_url) : '-'}</div>
				</SummaryCell>
				<SummaryCell title="Campaign Hashtags and Mentions" isBlock>
					<div>{form.mentions.length > 0 ? form.mentions.join(', ') : '-'}</div>
				</SummaryCell>
			</SummaryBox>

			<SummaryBox title="Influencers Detail" editUrl="/create/detail">
				{sources.map((source, index) => (
					<div className="campaign-create-summary-source" key={`source-${index}`}>
						<SummaryCell
							title="Username"
							renderBefore={() => (
								<div className="create-influencer-box-image">
									<img src={source.data?.profile_pic_url} alt={source.name} />
								</div>
							)}
						>
							{source.name}
						</SummaryCell>
						<SummaryCell title="Followers" isForth>
							{Humanize.compactInteger(source.data?.follower_count ?? 0, 1)}
						</SummaryCell>
						<SummaryCell title="Cost" isForth>
							{source.cost ? `$${source.cost}` : 'Unlimited'}
						</SummaryCell>
						{source.medias
							.filter((media) => getValidUrl(media.landing_url !== '' ? media.landing_url : form.landing_url) !== '')
							.length > 0 && (
							<SummaryCell
								title="Campaign Landing URLs"
								isBlock
								className="source-landing-urls"
							>
								{source.medias
									.filter((media) => getValidUrl(media.landing_url !== '' ? media.landing_url : form.landing_url) !== '')
									.map((media, i) => (
									<div key={i}>{getValidUrl(media.landing_url !== '' ? media.landing_url : form.landing_url)}</div>
								))}
							</SummaryCell>
						)}
					</div>
				))}
			</SummaryBox>
		</div>
	);
}

Step4.propTypes = {
	seenFirst: PropTypes.bool.isRequired,
	sources: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	form: PropTypes.shape().isRequired,
	getValidUrl: PropTypes.func.isRequired,
};
