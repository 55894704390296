import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { Box, Info } from 'interceptd-ui';

import './DataBox.css';

function DataBox({ title, data }) {
	return (
		<Box title={title} bodyProps={{ className: 'data-box' }}>
			{data.map((d) => (
				<div key={d.name} className="data-box-item">
					<div>
						<div className="data-box-name">
							<span>{d.name}</span>
							{d.description ? <Info tooltipProps={{ position: 'right' }}>{d.description}</Info> : null}
						</div>
						{d.subTitle && <span className="data-box-name-sub-title">{d.subTitle}</span>}
					</div>
					<span className="data-box-value">{d.value}</span>
				</div>
			))}
		</Box>
	);
}

DataBox.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({
		subTitle: PropTypes.string,
		name: PropTypes.string,
		value: PropTypes.string,
	})).isRequired,
};

export default React.memo(DataBox, (p, n) => isEqual(p, n));
