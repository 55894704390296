import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import { Icon, TagInput } from 'interceptd-ui';

import InfluencerLogo from '../../assets/instagram_logo.jpeg';

import './styles/Step1.css';

const UsernameRegex = /^(@?)[^\W][\w.]{1,29}$/gi;

export default function Step1({
	influencers,
	onChange,
	setSeenFirst,
	leftInfluencerCount,
}) {
	useEffect(() => {
		setSeenFirst(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAddInfluencer = (value) => {
		const newInf = uniq(value)
			.map((item) => (item).trim().toLowerCase().replace(/\s/g, '').replace(/-/g, ''))
			.filter((item) => item !== '' && item !== '@')
			.filter((item) => item.match(UsernameRegex))
			.map((item) => item[0] === '@' ? item : `@${item}`);
		if (leftInfluencerCount >= newInf.length) {
			onChange(newInf);
		}
	};

	return (
		<div className="campaign-create-inner">
			<div style={{
				display: 'flex',
				alignItems: 'center',
				fontSize: 14,
				color: 'var(--darkgray)',
				marginBottom: 10,
			}}
			>
				<Icon i="info" size={18} style={{ marginRight: 10 }} />
				<span>
					You can paste as many usernames as you’d like.
					<span style={{ color: 'var(--green)' }}> (Each one on a line)</span>
				</span>
			</div>
			<TagInput
				name={`name-${Math.random().toString(36).substr(2, 9)}`}
				label={(
					<div className="influencer-input-label">
						<span>Instagram username</span>
						<img src={InfluencerLogo} alt="instagram" />
					</div>
				)}
				placeholder="@john"
				addOnBlur
				value={influencers}
				onChange={handleAddInfluencer}
				inputProps={{
					autoFocus: true,
				}}
			/>
		</div>
	);
}

Step1.propTypes = {
	influencers: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChange: PropTypes.func.isRequired,
	setSeenFirst: PropTypes.func.isRequired,
	leftInfluencerCount: PropTypes.number.isRequired,
};
