import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'interceptd-ui';

import './More.css';

function More({
	icon,
	iconProps,
	children,
	...rest
}) {
	return (
		<div className="more-button" {...rest}>
			{icon && <Icon i={icon} size={14} {...iconProps} />}
			<div className="more-button-content">
				{children}
			</div>
		</div>
	);
}

More.propTypes = {
	icon: PropTypes.string,
	iconProps: PropTypes.shape(),
	children: PropTypes.node.isRequired,
};

More.defaultProps = {
	icon: 'plus',
	iconProps: null,
};

export default React.memo(More);
