import React from 'react';
import Humanize from 'humanize-plus';
import { Info, Tooltip } from 'interceptd-ui';

import Calculating from '../../components/Calculating';

import {
	engagementRateDescription,
	authenticEngagementRateDescription,
	estimatedReachDescription,
	qualifiedAudienceDescription,
} from '../../constants/descriptions';

const Columns = () => [
	{
		Header: 'Influencer Name',
		id: 'nameColumn',
		accessor: 'nameColumn',
		cast: 'string',
		style: {
			overflow: 'visible',
		},
		minWidth: 220,
	},
	{
		Header: 'Following',
		id: 'following',
		cast: 'number',
		sortable: true,
		accessor: 'data.follow_count',
		Cell: ({ value }) => Humanize.compactInteger(value, 1).toUpperCase(),
	},
	{
		Header: 'Posts',
		id: 'media_count',
		cast: 'number',
		sortable: true,
		accessor: 'data.media_count',
		Cell: ({ value }) => Humanize.compactInteger(value, 2).toUpperCase(),
	},
	{
		Header: () => (
			<div className="column-header-with-info">
				<Info tooltipProps={{ position: 'left' }}>{qualifiedAudienceDescription}</Info>
				<span>Qualified Audience</span>
			</div>
		),
		id: 'qualifiedAudience',
		cast: 'number',
		sortable: true,
		accessor: 'qualifiedAudience',
		Cell: ({ value }) => value ? Humanize.compactInteger(value, 2).toUpperCase() : <Calculating />,
	},
	{
		Header: () => (
			<div className="column-header-with-info">
				<Info tooltipProps={{ position: 'left' }}>{engagementRateDescription}</Info>
				<span>Engagement Rate</span>
			</div>
		),
		id: 'engagement',
		cast: 'number',
		sortable: true,
		accessor: 'engagement',
		Cell: ({ value, original }) => value ? (
			<div style={{ display: 'inline-block' }}>
				<Tooltip tooltip={original.averageRate}>{`${value}%`}</Tooltip>
			</div>
		) : 'N/A',
	},
	{
		Header: () => (
			<div className="column-header-with-info">
				<Info tooltipProps={{ position: 'left' }}>{authenticEngagementRateDescription}</Info>
				<span>Authentic Engagement Rate</span>
			</div>
		),
		id: 'authenticEngagementRate',
		cast: 'number',
		sortable: true,
		accessor: 'authenticEngagementRate',
		Cell: ({ value, original }) => value ? (
			<div style={{ display: 'inline-block' }}>
				<Tooltip tooltip={original.averageRate}>
					{value}%
				</Tooltip>
			</div>
		) : <Calculating />,
	},
	{
		Header: () => (
			<div className="column-header-with-info">
				<Info tooltipProps={{ position: 'left' }}>{estimatedReachDescription}</Info>
				<span>Potential Reach</span>
			</div>
		),
		id: 'estimatedReach',
		cast: 'number',
		sortable: true,
		accessor: 'estimatedReach',
		Cell: ({ value }) => (value ? Humanize.compactInteger(value, 1).toUpperCase() : <Calculating />),
	},
];

export default Columns;
