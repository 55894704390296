import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import useLocal from '../../services/use-local';

export default function LoginAs({ match }) {
	const { resetStore } = useLocal();

	if (match.params.token) {
		resetStore({
			login_as: true,
			shadow_login: /shadow_login/gi.test(window.location.search),
			token: match.params.token,
		});
	}

	return <Redirect to="/" />;
}

LoginAs.propTypes = {
	match: PropTypes.shape().isRequired,
};
