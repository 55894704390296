import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from 'interceptd-ui';
import { misc } from 'interceptd-ui/dist/utils';

import Button from '../../components/Button';

import Sidebar from './components/Sidebar';
import Header from './components/Header';

import Api from '../../services/api';
import useLocal from '../../services/use-local';

import './styles/Login.css';

export default function Register() {
	const [form, setForm] = React.useState({
		fullname: '',
		email: misc.getQueryParams('userMail') || '',
		companyName: '',
		plainPassword: {
			first: '',
			second: '',
		},
	});
	const [error, setError] = React.useState(false);
	const [disabled, setDisabled] = React.useState(true);
	const [fetching, setFetching] = React.useState(false);
	const [redirect, setRedirect] = React.useState(false);
	const [to, setTo] = React.useState('/verify');
	const { setStoreItem, resetStore } = useLocal();

	React.useEffect(() => {
		resetStore();
		Api.setToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const {
			fullname,
			companyName,
			email,
			plainPassword,
		} = form;

		setError((plainPassword.first !== '' || plainPassword.second !== '') && plainPassword.first !== plainPassword.second);
		setDisabled(fullname.trim() === '' || companyName.trim() === '' || email === '' || !misc.validateEmail(email) || plainPassword.first === '' || plainPassword.second === '' || plainPassword.first !== plainPassword.second);
	}, [form]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (fetching) return false;

		setFetching(true);
		try {
			const payload = {
				fullname: form.fullname.trim(),
				email: form.email.trim(),
				company_name: form.companyName.trim(),
				password: form.plainPassword.first,
				gdpr: true,
				query: '?ref=influencer',
			};

			const register = await Api.register(payload);
			setStoreItem('token', register.data.token);
			setStoreItem('login_as', false);
			Api.setToken(register.data.token);
			setRedirect(true);
			setTo({
				pathname: '/verify',
				state: { email: form.email },
			});
		} catch ({ response }) {
			setFetching(false);
			toast.error(response?.data?.error?.message || 'Something went wrong.');
		}

		return false;
	};

	const handleInput = ({ target }) => {
		setForm((prevForm) => ({
			...prevForm,
			[target.name]: target.value,
		}));
	};

	const handlePassword = (event, order) => {
		setForm((prevForm) => ({
			...prevForm,
			plainPassword: {
				...prevForm.plainPassword,
				[order]: event?.target?.value.trim(),
			},
		}));
	};

	if (redirect) return <Redirect to={to} />;

	return (
		<div className="login">
			<Helmet>
				<title>Influencer Dashboard - Register</title>
			</Helmet>
			<div className="login-wrapper">
				<Sidebar />
				<div className="login-right">
					<Header />
					<form className="login-form" onSubmit={handleSubmit}>
						<div className="login-title">
							<h1>Create Your Free Account</h1>
						</div>
						<div className="login-form-inner">
							<Input
								type="text"
								name="fullname"
								placeholder="John Doe"
								label="Full Name"
								value={form.fullname}
								onChange={handleInput}
							/>
							<Input
								type="text"
								name="companyName"
								placeholder="Example Inc"
								label="Company Name"
								value={form.companyName}
								onChange={handleInput}
							/>
							<Input
								type="email"
								name="email"
								placeholder="john@doe.com"
								label="Email"
								value={form.email}
								onChange={handleInput}
							/>
							<Input
								type="password"
								name="first"
								label="Password"
								value={form.plainPassword.first}
								min="6"
								onChange={(event) => handlePassword(event, 'first')}
								error={form.plainPassword.first && form.plainPassword.first.length < 6 && 'Password must be at least 6 character.'}
							/>
							<Input
								type="password"
								name="second"
								label="Confirm Password"
								value={form.plainPassword.second}
								onChange={(event) => handlePassword(event, 'second')}
								error={error && 'Passwords are not same'}
							/>
							<Button type="submit" block loading={fetching} disabled={disabled}>Sign Up</Button>
							<span className="disclamer">
								By clicking "Sign Up", you agree to Interceptd's
								{' '}
								<a href="https://interceptd.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Term of Services</a>
								.
							</span>
							<Button to="/welcome" block bgColor="transparent">I have an account</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
