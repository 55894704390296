import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { transform } from 'interceptd-ui/dist/utils';
import { subMonths } from 'date-fns';
import {
	PageTitle,
	FilterBox,
	DatePicker,
} from 'interceptd-ui';

import PlanDetail from '../../components/PlanDetail';

import CampaignList from './CampaignList';

import Api from '../../services/api';
import useLocal from '../../services/use-local';
import useSilo from '../../services/use-silo';

import './styles/List.css';

export default function List() {
	const { store, setStoreItem } = useLocal();
	const { silo } = useSilo();

	const [dateFilter, setDateFilter] = useState({
		from: store?.start_date ? new Date(store?.start_date) : subMonths(new Date(), 1),
		to: store?.end_date ? new Date(store?.end_date) : new Date(),
	});
	const [clickStats, setClickStats] = useState([]);

	const medias = silo?.data?.medias;

	useEffect(() => {
		const getData = async () => {
			if (!medias || medias.length === 0) return false;

			try {
				const clickStatsResponse = await Api.getStats({
					stat_type: 'click',
					media_ids: medias.map((m) => m.id).join(','),
					ts_start: transform.getTimeStamp(dateFilter.from),
					ts_end: transform.getTimeStamp(dateFilter.to, true),
				});
				const data = clickStatsResponse?.data?.data || [];

				setClickStats(data);
			} catch (err) {
				setClickStats((prevStats) => prevStats);
			}
		};
		getData();
	}, [medias, dateFilter]);

	return (
		<div className="campaign-list">
			<PageTitle>
				<PageTitle.Title>Campaigns</PageTitle.Title>
				<PlanDetail />
			</PageTitle>

			<FilterBox>
				<span />
				<FilterBox.Right>
					<DatePicker
						value={{
							from: dateFilter.from,
							to: dateFilter.to,
						}}
						onChange={(dateObject) => {
							setDateFilter(dateObject);
							setStoreItem('start_date', dateObject.from);
							setStoreItem('end_date', dateObject.to);
						}}
					/>
				</FilterBox.Right>
			</FilterBox>

			<CampaignList clickStats={clickStats} />

		</div>
	);
}

List.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
	}).isRequired,
};
