import React, {
	useReducer,
	createContext,
	useContext,
} from 'react';
import PropTypes from 'prop-types';

const SET_ITEM = 'SET_ITEM';
const SET_SILO = 'SET_SILO';
export const SiloContext = createContext();
const initialState = {
	data: {
		loading: true,
		campaigns: [],
		influencers: [],
		sources: [],
		medias: [],
	},
	currentPlan: {
		name: 'Trial',
		price: 0,
		limits: {
			inf_limit: 3,
			post_limit: 6,
			story_limit: 9,
		},
	},
};

const siloReducer = (state, action) => {
	switch (action.type) {
	case SET_ITEM:
		return {
			...state,
			[action.key]: action.payload,
		};
	case SET_SILO:
		return action.payload;
	default:
		return state;
	}
};

export function SiloProvider({ children }) {
	const [state, dispatch] = useReducer(siloReducer, initialState);

	return (
		<SiloContext.Provider value={[state, dispatch]}>
			{children}
		</SiloContext.Provider>
	);
}

SiloProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default function useSilo() {
	const [state, dispatch] = useContext(SiloContext);

	const setSiloItem = (key, value) => dispatch({
		type: SET_ITEM,
		key,
		payload: value,
	});

	const removeSiloItem = (key) => dispatch({
		type: SET_ITEM,
		key,
		payload: undefined,
	});

	const resetSilo = (value) => dispatch({
		type: SET_SILO,
		payload: value || initialState,
	});

	return {
		silo: state,
		setSiloItem,
		removeSiloItem,
		resetSilo,
	};
}
