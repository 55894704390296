import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import round from 'lodash/round';
import isEqual from 'lodash/isEqual';
import truncate from 'lodash/truncate';
import Humanize from 'humanize-plus';
import { Row, Col } from 'react-flexbox-grid';
import { PageTitle, Breadcrumbs } from 'interceptd-ui';
import { format } from 'date-fns';

import Chart from '../../components/Chart';
import Calculating from '../../components/Calculating';
import PlanDetail from '../../components/PlanDetail';

import InfluencerCard from './components/InfluencerCard';
import DataBox from './components/DataBox';

import {
	commentRateDescription,
	engagementRateDescription,
	authenticEngagementRateDescription,
	averageCommentsDescription,
	averageLikesDescription,
	qualifiedAudienceDescription,
	audienceAuthenticityDescription,
	estimatedReachDescription,
	audienceReachabilityDescription,
} from '../../constants/descriptions';

import Api from '../../services/api';
import { SiloContext } from '../../services/use-silo';

import {
	calculateAudienceReachability,
	calculateEstimatedReach,
	calculateAuthenticEngagementScore,
	calculateAudienceScore,
	calculateFollowerCount,
} from '../../util/helpers/calculations';
import { getAvgEngRateMessage, mentionAndHashtagIsExists } from '../../util/misc';

import './styles/InfluencerDetail.css';

import InfluencerLogo from '../../assets/icon.svg';
import InstagramLogo from '../../assets/instagram.svg';

export default class InfluencerDetail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			influencer: null,
			loading: true,
			engagementRate: 'N/A',
			averageLike: 'N/A',
			averageComment: 'N/A',
			commentRate: 'N/A',

			estimatedReach: 'N/A',
			qualifiedAudience: 'N/A',
			audienceAuthenticity: 'N/A',
			authenticEngagement: 'N/A',
			estimatedAudienceReachability: 'N/A',
			audienceChartData: {
				labels: [],
				data: [],
			},

			sponsorPosts: [],
			organicPosts: [],
			postImageMap: {},
			postMentionMap: {},
		};
	}


	componentDidMount() {
		this.getInfluencer();
	}

	getInfluencer = async () => {
		try {
			const {
				match: {
					params: {
						username,
						type,
					},
				},
			} = this.props;

			const response = await Api.getInfluencer({ username, type });
			const influencer = response.data.code.toLowerCase() === 'ok'
				? response?.data?.data?.data
				: null;

			if (!influencer) {
				return this.setState({ loading: false });
			}

			const audienceScore = response?.data?.data.audience_score || {};
			const engagementScore = response?.data?.data.engagement_score || {};
			const followerCount = influencer.follower_count;

			const medias = influencer?.media || [];
			const sponsorPosts = [];
			const organicPosts = [];
			const postImageMap = {};
			const postMentionMap = {};

			influencer.averageRate = getAvgEngRateMessage(followerCount);

			medias.filter((m) => m.type !== 'link')
				.forEach((m) => {
					const caption = (m?.captions || []).join(' ');
					const mentions = caption.split(' ')
						.filter((c) => c.startsWith('@'))
						.map((h) => h.replace(/\W/g, ' ').trim().replace(/ /g, ''));
					const hashtags = caption.split(' ')
						.filter((c) => c.startsWith('#'))
						.map((h) => h.replace(/\W/g, ' ').trim().replace(/ /g, ''));

					const engagement = ((m.likes_count + m.comment_count) / followerCount) * 100;
					const date = new Date(1970, 0, 1);
					date.setSeconds(m.ts_created);

					const data = {
						x: date,
						y: engagement.toFixed(10),
					};

					postImageMap[data.y] = m.display_url;
					postMentionMap[data.y] = [
						...mentions.map((m) => `@${m}`),
						...hashtags.map((h) => `#${h}`),
					];
					if (mentionAndHashtagIsExists(caption)) {
						sponsorPosts.push(data);
					} else {
						organicPosts.push(data);
					}
				});

			const { totalRealFollower } = calculateFollowerCount(audienceScore, followerCount);
			const audienceChartData = this.calculateAudienceChartData(audienceScore);

			const {
				qualifiedAudience,
				audienceAuthenticity,
			} = calculateAudienceScore(audienceScore, followerCount);
			const estimatedReach = calculateEstimatedReach({
				posts: medias.filter((m) => m.type !== 'link'),
				realFollowers: totalRealFollower,
			});

			const estimatedAudienceReachability = calculateAudienceReachability({
				posts: medias.filter((m) => m.type !== 'link'),
				followers: followerCount,
				realFollowers: totalRealFollower,
			});

			delete influencer?.media;

			let postCount = 0;
			let totalLike = 0;
			let totalComment = 0;

			const totalEngagement = medias.reduce((total, curr) => {
				if (curr.type === 'link') return total;

				postCount += 1;
				totalLike += curr.likes_count;
				totalComment += curr.comment_count;
				return (total + curr.likes_count + curr.comment_count);
			}, 0);

			const engagementRate = (totalEngagement > 0 && influencer.follower_count > 0)
				? (totalEngagement / (influencer.follower_count * postCount)) * 100
				: null;


			const authenticEngagement = calculateAuthenticEngagementScore(
				engagementScore,
				engagementRate,
			);

			this.setState({
				sponsorPosts,
				organicPosts,
				influencer,
				postImageMap,
				postMentionMap,
				engagementRate: engagementRate ? `${engagementRate.toFixed(2)}%` : 'N/A',
				audienceChartData,
				qualifiedAudience,
				audienceAuthenticity,
				loading: false,
				averageRate: influencer.averageRate,
				authenticEngagement: authenticEngagement ? `${authenticEngagement}%` : <Calculating tooltipPosition="left" />,
				estimatedAudienceReachability: estimatedAudienceReachability ? `${estimatedAudienceReachability.toFixed(2)}%` : <Calculating tooltipPosition="left" />,
				estimatedReach: estimatedReach ? Humanize.compactInteger(estimatedReach, 1).toUpperCase() : <Calculating tooltipPosition="left" />,
				commentRate: `${round(totalComment / influencer.follower_count, 3).toFixed(2)}%`,
				averageLike: `${Humanize.compactInteger((totalLike / postCount), 1)}`,
				averageComment: `${Humanize.compactInteger((totalComment / postCount), 1)}`,
			});
		} catch (err) {
			this.setState({
				loading: false,
			});
		}
	}

	calculateAudienceChartData = (audienceScore) => {
		if (isEqual(audienceScore, {})) {
			return {
				labels: [],
				data: [],
			};
		}

		const totalAudience = audienceScore.other
			+ audienceScore.business
			+ audienceScore.influencer
			+ audienceScore.mass_follower
			+ audienceScore.potential_bot
			+ audienceScore.potential_fake
			+ audienceScore.abnormal_pattern;
		const totalFakeAudience = audienceScore.potential_bot
			+ audienceScore.potential_fake
			+ audienceScore.abnormal_pattern;

		// percents
		const realAccount = +((audienceScore.other / totalAudience) * 100).toFixed(2);
		const businessAccount = +((audienceScore.business / totalAudience) * 100).toFixed(2);
		const influencerAccount = +((audienceScore.influencer / totalAudience) * 100).toFixed(2);
		const massFollowerAccounts = +((audienceScore.mass_follower / totalAudience) * 100).toFixed(2);
		const suspiciousAccounts = +((totalFakeAudience / totalAudience) * 100).toFixed(2);

		const audienceChartData = {
			labels: [
				`Real Accounts (${realAccount}%)`,
				`Business Accounts (${businessAccount}%)`,
				`Influencer Accounts (${influencerAccount}%)`,
				`Mass Follower Accounts (${massFollowerAccounts}%)`,
				`Suspicious Accounts (${suspiciousAccounts}%)`,
			],
			data: [
				realAccount,
				businessAccount,
				influencerAccount,
				massFollowerAccounts,
				suspiciousAccounts,
			],
		};

		return audienceChartData;
	}

	render() {
		const {
			loading,
			influencer,
			engagementRate,
			averageLike,
			averageComment,
			commentRate,
			audienceChartData,
			qualifiedAudience,
			audienceAuthenticity,
			sponsorPosts,
			organicPosts,
			authenticEngagement,
			estimatedReach,
			estimatedAudienceReachability,
			postImageMap,
			postMentionMap,
			averageRate,
		} = this.state;
		const { match } = this.props;

		if (!loading && !influencer) {
			return <Redirect to="/404-not-found" />;
		}
		console.log({ sponsorPosts, organicPosts });
		return (
			<div>
				<PageTitle>
					<div>
						<Breadcrumbs>
							<Breadcrumbs.Crumb><Link to="/">Influencer List</Link></Breadcrumbs.Crumb>
							<Breadcrumbs.Crumb><Link to={match.url}>{influencer?.username}</Link></Breadcrumbs.Crumb>
						</Breadcrumbs>
						<PageTitle.Title>Influencer Detail</PageTitle.Title>
					</div>
					<PlanDetail />
				</PageTitle>
				<div className="influencer-detail">
					<InfluencerCard
						influencer={influencer}
						engagementRate={engagementRate}
						authenticEngagement={authenticEngagement}
					/>

					<div className="page-separator-title">
						<span>Audience</span>
					</div>

					<Row>
						<Col xs={12} lg={6}>
							<Chart
								alignCenter
								title="Audience Type"
								type="pie"
								width="550"
								series={audienceChartData?.data || []}
								loading={false}
								getOptions={(options) => ({
									...options,
									labels: audienceChartData?.labels || [],
									legend: {
										width: 240,
										fontSize: '14px',
									},
									dataLabels: {
										enabled: true,
										textAnchor: 'middle',
									},
								})}
							/>
						</Col>
						<Col xs={12} lg={6}>
							<DataBox
								title="Audience Details"
								data={[{
									name: 'Qualified Audience',
									value: qualifiedAudience === '-' ? <Calculating tooltipPosition="left" /> : qualifiedAudience,
									description: qualifiedAudienceDescription,
								}, {
									name: 'Audience Authencity',
									value: audienceAuthenticity === '-' ? <Calculating tooltipPosition="left" /> : audienceAuthenticity,
									description: audienceAuthenticityDescription,
								}, {
									name: 'Potential Reach',
									value: estimatedReach,
									description: estimatedReachDescription,
								}, {
									name: 'Audience Reachability',
									value: estimatedAudienceReachability,
									description: audienceReachabilityDescription,
								}]}
							/>
						</Col>
					</Row>

					<div className="page-separator-title">
						<span>Engagement</span>
					</div>

					<Row>
						<Col xs={12} lg={6}>
							<Chart
								alignCenter
								title="Engagement Rate Distribution"
								height={350}
								type="scatter"
								width="500"
								series={[
									{
										name: 'Sponsored Post',
										data: sponsorPosts,
									},
									{
										name: 'Organic Post',
										data: organicPosts,
									},
								]}
								getOptions={(ops) => ({
									...ops,
									tooltip: {
										custom: ({ series, seriesIndex, dataPointIndex }) => {
											const val = series[seriesIndex][dataPointIndex];
											const title = seriesIndex === 0 ? 'Sponsored' : 'Organic';
											const captions = postMentionMap[val];
											const mentionsAndHashtags = (captions || []).join(', ');
											return `
												<div class="post-tooltip">
													<div class="post-tooltip-header">
													<span class="post-tooltip-title ${title.toLowerCase()}">${title} Post</span>
														<span class="post-tooltip-value">${val.toFixed(2)}%</span>
													</div>
													${mentionsAndHashtags.length > 0 ? `<div class="post-tooltip-mentions">${truncate(mentionsAndHashtags, { length: 26 })}</div>` : ''}
													<img src="${postImageMap[val]}" alt="Post" />
												</div>
											`;
										},
									},
									chart: {
										width: '100%',
										type: 'scatter',
										animations: {
											enabled: false,
										},
										zoom: {
											enabled: false,
										},
										toolbar: {
											show: false,
										},
									},
									colors: [
										'#230060',
										'#D2376A',
									],
									xaxis: {
										type: 'datetime',
										categories: [],
										labels: {
											formatter: (value) => format(value, 'dd MMM yy'),
											rotateAlways: true,
											minHeight: 80,
											maxHeight: 120,
											offsetY: 10,
										},
									},
									yaxis: [{
										title: {
											text: 'Engagement Rate',
										},
										labels: {
											formatter: (value) => `${(value).toFixed(2)}%`,
										},
									}],
									markers: {
										size: 12,
									},
									fill: {
										type: 'image',
										opacity: 1,
										image: {
											src: [
												InfluencerLogo,
												InstagramLogo,
											],
											width: 23,
											height: 23,
										},
									},
									legend: {
										labels: {
											useSeriesColors: true,
										},
										markers: {
											customHTML: [
												() => `<span class="chart-marker-logo"><img src="${InfluencerLogo}" /></span>`,
												() => `<span class="chart-marker-logo"><img src="${InstagramLogo}" /></span>`,
											],
										},
									},
								})}
								loading={false}
							/>
						</Col>
						<Col xs={12} lg={6}>
							<DataBox
								title="Engagement Details"
								data={[{
									name: 'Average Likes',
									value: averageLike,
									description: averageLikesDescription,
								}, {
									name: 'Average Comments',
									value: averageComment,
									description: averageCommentsDescription,
								}, {
									name: 'Engagement Rate',
									value: engagementRate,
									subTitle: averageRate,
									description: engagementRateDescription,
								}, {
									name: 'Authentic Engagement Rate',
									value: authenticEngagement,
									subTitle: averageRate,
									description: authenticEngagementRateDescription,
								}, {
									name: 'Comment Rates',
									value: commentRate,
									description: commentRateDescription,
								}]}
							/>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

InfluencerDetail.contextType = SiloContext;

InfluencerDetail.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			username: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};
