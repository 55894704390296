import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Humanize from 'humanize-plus';
import isEqual from 'lodash/isEqual';
import { Loader, Tooltip, Icon } from 'interceptd-ui';

import LargeAvatar from '../LargeAvatar';

import './InfluencerCard.css';

function InfluencerCard({ influencer, engagementRate, authenticEngagement }) {
	return (
		<div className="influencer-card">
			<div className="influencer-info-wrapper">
				{!influencer ? <Loader mini /> : (
					<Fragment>
						<LargeAvatar
							src={influencer?.profile_pic_url}
							alt={influencer?.username}
						/>
						<div className="influencer-info">
							<span className="influencer-name">{influencer?.full_name.replace(/\?/g, '')}</span>
							<span className="influencer-bio">@{influencer?.username.replace(/\?/g, '')}</span>
						</div>
					</Fragment>
				)}
			</div>

			<div className="influencer-stats-wrapper">
				<div className="influencer-stat">
					<span className="stat-name">Followers</span>
					<span className="stat-data">{Humanize.compactInteger(influencer?.follower_count || 0, 1)}</span>
				</div>
				<div className="influencer-stat">
					<span className="stat-name">Following</span>
					<span className="stat-data">{Humanize.compactInteger(influencer?.follow_count || 0, 1)}</span>
				</div>
				<div className="influencer-stat">
					<span className="stat-name">Post</span>
					<span className="stat-data">{Humanize.compactInteger(influencer?.media_count || 0, 1)}</span>
				</div>
				<div className="influencer-stat">
					<span className="stat-name">Engagement Rate</span>
					<div className="stat-data">
						{engagementRate}
						<div className="stat-data-icon">
							<Tooltip tooltip={influencer?.averageRate}>
								<Icon i="info" size="15" />
							</Tooltip>
						</div>
					</div>
				</div>
				<div className="influencer-stat">
					<span className="stat-name">Authentic Engagement Rate</span>
					<div className="stat-data">
						{authenticEngagement}
						<div className="stat-data-icon">
							<Tooltip tooltip={influencer?.averageRate}>
								<Icon i="info" size="15" />
							</Tooltip>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

InfluencerCard.propTypes = {
	influencer: PropTypes.shape({
		full_name: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
	}),
	engagementRate: PropTypes.string,
	authenticEngagement: PropTypes.string,
};

InfluencerCard.defaultProps = {
	engagementRate: 'N/A',
	authenticEngagement: 'N/A',
	influencer: null,
};

export default React.memo(InfluencerCard, (prevProps, nextProps) => isEqual(prevProps, nextProps));
