import React from 'react';
import { Redirect } from 'react-router-dom';

import Api from '../../services/api';
import useLocal from '../../services/use-local';

export default function Logout() {
	const { store, resetStore } = useLocal();
	resetStore({});
	Api.setToken();

	return !store?.token && <Redirect to="/welcome" />;
}
