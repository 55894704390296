import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import uniq from 'lodash/uniq';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Humanize from 'humanize-plus';
import {
	subMonths,
} from 'date-fns';
import {
	PageTitle,
	FilterBox,
	DatePicker,
	Box,
	Loader,
	Select,
	Breadcrumbs,
} from 'interceptd-ui';
import { transform } from 'interceptd-ui/dist/utils';

import Table from '../../components/Table';
import StatsBox from '../../components/StatsBox';
import Chart from '../../components/Chart';
import PlanDetail from '../../components/PlanDetail';

import MediaTable from './components/MediaTable';
import InstagramPost from './components/InstagramPost';

import Api from '../../services/api';
import useLocal from '../../services/use-local';
import useSilo from '../../services/use-silo';

import {
	calculateFollowerCount,
	calculateEstimatedReach,
	calculateAuthenticEngagementScore,
} from '../../util/helpers/calculations';

import RuleNameMap from '../../constants/rules';
import Countries from '../../constants/countries';

import InfluencerColumns from './DetailInfluencerListColumns';

import './styles/Detail.css';

const BASE_STATS = {
	flag_reasons: [],
	flags: 0,
	clean: 0,
	total: 0,
};

export default function Detail() {
	const { id } = useParams();
	const { silo } = useSilo();
	const { setStoreItem, store } = useLocal();
	const [dateFilter, setDateFilter] = useState({
		from: store?.start_date ? new Date(store?.start_date) : subMonths(new Date(), 1),
		to: store?.end_date ? new Date(store?.end_date) : new Date(),
	});

	const [state, setState] = useState({
		loading: true,
		sources: [],
		medias: [],
		influencers: [],
	});

	const [clickDistLoading, setClickDistLoading] = useState(true);
	const [countryDistLoading, setCountryDistLoading] = useState(true);
	const [statsLoading, setStatsLoading] = useState(true);

	const [resized, setResized] = useState([]);
	const [expandedState, setExpandedState] = useState({});
	const [clickTimeDistData, setClickTimeDistData] = useState([]);

	const [countryDistData, setCountryDistData] = useState({
		series: [],
		labels: [],
	});
	const [clickDistData, setClickDistData] = useState({
		series: [],
		labels: [],
	});
	const [sClickDistData, setSClickDistData] = useState({
		series: [],
		labels: [],
	});
	const [flagReasonData, setFlagReasonData] = useState({
		series: [],
		labels: [],
	});
	const [stats, setStats] = useState({
		campaign: {},
		sources: {},
		medias: {},
	});
	const [postSorting, setPostSorting] = useState('engagement');

	useEffect(() => {
		const fetchCampaign = () => {
			if (silo?.data?.campaigns.length === 0 || silo?.data?.sources.length === 0 || silo?.data?.influencers.length === 0 || silo?.data?.medias.length === 0) {
				return;
			}

			const campData = (silo?.data.campaigns).find((c) => +c.id === +id);
			const mediaData = (silo?.data.medias).filter((m) => +m.campaign_id === +id);
			const sourceData = (silo?.data.sources).filter((s) => +s.campaign_id === +id);
			const influencerIds = sourceData.map((s) => s.influencer_id);
			const infData = (silo?.data.influencers).filter((i) => influencerIds.indexOf(i.id) > -1);

			let campaignRateTotal = 0;
			const sources = sourceData.map((s) => {
				const source = s;

				const posts = mediaData.filter((m) => m.type !== 'link' && m.source_id === source.id).map((m) => m.data);
				source.postMedias = mediaData.filter((m) => m.type !== 'link' && m.source_id === source.id);
				source.influencer = infData.find((i) => i.id === source.influencer_id);

				const influencerPosts = source.influencer.data.media.filter((m) => m.type !== 'link');
				const followerCount = source?.influencer?.data?.follower_count || 0;
				const engPostCount = source.postMedias.length;
				const engagementScore = source.influencer.engagement_score;

				const { totalRealFollower } = calculateFollowerCount(source.influencer.audience_score, source.influencer.data?.follower_count);
				const estimatedReach = calculateEstimatedReach({ posts, realFollowers: totalRealFollower });

				source.estimatedReach = Number.isNaN(estimatedReach) ? 'N/A' : Humanize.compactInteger(estimatedReach, 1).toUpperCase();

				let engCommentCount = 0;
				let engLikeCount = 0;
				let rateTotal = 0;
				source.postMedias.forEach((m) => {
					const eng = (((m?.data?.comment_count || 0) + (m?.data?.likes_count || 0)) / followerCount) * 100;

					const er = calculateAuthenticEngagementScore(
						engagementScore,
						eng,
					);
					if (er) rateTotal += +er;
					engCommentCount += m?.data?.comment_count || 0;
					engLikeCount += m?.data?.likes_count || 0;
				});
				campaignRateTotal += +rateTotal;
				const likeComment = (engCommentCount + engLikeCount);
				const followerPost = (followerCount * engPostCount);

				let engagementData = 'N/A';
				if (source.postMedias.length > 0) {
					engagementData = (likeComment > 0 && followerPost > 0)
						? ((likeComment / followerPost) * 100)
						: 0;
				}
				source.engagement = engagementData !== 'N/A'
					? `${engagementData.toFixed(2)}%`
					: engagementData;

				const totalEngagement = influencerPosts.reduce((acc, curr) => (
					acc + (curr?.comment_count || 0) + (curr?.likes_count || 0)
				), 0);

				source.influencer.engagement = (totalEngagement > 0 && followerCount > 0)
					? ((totalEngagement / (followerCount * influencerPosts.length)) * 100).toFixed(2)
					: null;

				source.authenticEngagementRate = rateTotal / source.postMedias.length;
				return source;
			});

			if (campData) campData.authEngRate = (campaignRateTotal / mediaData.length);


			const campaignLinkList = mediaData.filter((m) => m.type === 'link');
			const campaignPostList = mediaData.filter((m) => m.type !== 'link');

			setState({
				sources,
				campaign: campData,
				medias: mediaData,
				influencers: infData,
				loading: false,
				totalStats: {
					totalSource: sourceData.length,
					totalMedia: {
						link: campaignLinkList.length,
						post: campaignPostList.length,
					},
					totalEngagement: {
						like: campaignPostList.reduce((acc, curr) => (curr?.data?.likes_count || 0) + acc, 0),
						comment: campaignPostList.reduce((acc, curr) => (curr?.data?.comment_count || 0) + acc, 0),
						rate: campData.engagementRate,
					},
				},
			});
		};
		fetchCampaign();
	}, [id, silo]);

	const getStats = async () => {
		setStatsLoading(true);

		try {
			const statsRes = await Api.getStats({
				stat_type: 'click',
				media_ids: state.medias.map((m) => m.id).join(','),
				ts_start: transform.getTimeStamp(dateFilter.from),
				ts_end: transform.getTimeStamp(dateFilter.to, true),
			});
			const fetchedStats = statsRes?.data?.data || [];
			const reducedStats = fetchedStats.reduce((acc, cur) => {
				const { count } = cur;
				const influencer = state.influencers.find((i) => i.id === cur.influencer_id);
				const source = state.sources.find((s) => s.id === cur.source_id);

				acc.campaign = acc.campaign || {
					...BASE_STATS,
					influencer,
					source,
				};
				acc.sources[cur.source_id] = acc.sources[cur.source_id] || {
					...BASE_STATS,
					influencer,
					source,
				};
				acc.medias[cur.media_id] = acc.medias[cur.media_id] || {
					...BASE_STATS,
					influencer,
					source,
				};

				acc.campaign.total += count;
				acc.sources[cur.source_id].total += count;
				acc.medias[cur.media_id].total += count;

				if (cur.flag_reason) {
					const flagReason = RuleNameMap[cur.flag_reason] || cur.flag_reason;
					acc.flags[flagReason] = acc.flags[flagReason] || 0;
					acc.flags[flagReason] += count;
					acc.campaign.flags += count;
					acc.sources[cur.source_id].flags += count;
					acc.medias[cur.media_id].flags += count;
					acc.campaign.flag_reasons = uniq([
						...acc.campaign.flag_reasons,
						cur.flag_reason,
					]);
					acc.sources[cur.source_id].flag_reasons = uniq([
						...acc.sources[cur.source_id].flag_reasons,
						cur.flag_reason,
					]);
					acc.medias[cur.media_id].flag_reasons = uniq([
						...acc.medias[cur.media_id].flag_reasons,
						cur.flag_reason,
					]);
				} else {
					acc.campaign.clean += count;
					acc.sources[cur.source_id].clean += count;
					acc.medias[cur.media_id].clean += count;
				}

				return acc;
			}, {
				campaigns: {},
				sources: {},
				medias: {},
				flags: {},
			});

			const labels = Object.values(reducedStats.sources).map((d) => d?.influencer?.username || 'unknown');
			setClickDistData({
				labels,
				series: Object.values(reducedStats.sources).map((d) => d?.total),
			});
			setSClickDistData({
				labels,
				series: Object.values(reducedStats.sources).map((d) => d?.flags),
			});
			setFlagReasonData({
				labels: Object.keys(reducedStats.flags),
				series: Object.values(reducedStats.flags),
			});

			setStats(reducedStats);
			setStatsLoading(false);
		} catch (err) {
			setStatsLoading(false);
			console.error(err);
		}
	};

	const getClickTimeDist = async () => {
		setClickDistLoading(true);

		try {
			const statsRes = await Api.getStats({
				stat_type: 'click_time_distribution',
				media_ids: state.medias.map((m) => m.id).join(','),
				ts_start: transform.getTimeStamp(dateFilter.from),
				ts_end: transform.getTimeStamp(dateFilter.to, true),
			});
			const data = statsRes?.data?.data || [];

			const statsMap = {};
			data.forEach((d) => {
				const [inf] = state.influencers.filter((i) => i.id === d.influencer_id);

				if (!inf) return;

				statsMap[d.influencer_id] = statsMap[d.influencer_id] || {
					name: inf.username,
					type: 'line',
					data: [...Array(24)].map((h, i) => ({
						y: 0,
						x: i,
					})),
				};

				const index = statsMap[d.influencer_id].data
					.findIndex((singleData) => singleData.x === d.hour);
				statsMap[d.influencer_id].data[index].y += d.count;
			});

			Object.keys(statsMap).forEach((key) => {
				statsMap[key].data = statsMap[key].data.map((d) => ({
					...d,
					x: String(d.x),
				}));
			});

			setClickTimeDistData(Object.values(statsMap));
			setClickDistLoading(false);
		} catch (err) {
			setClickDistLoading(false);
			console.error(err);
		}
	};

	const getCountryDist = async () => {
		try {
			setCountryDistLoading(true);

			const statsRes = await Api.getStats({
				stat_type: 'country_distribution',
				media_ids: state.medias.map((m) => m.id).join(','),
				ts_start: transform.getTimeStamp(dateFilter.from),
				ts_end: transform.getTimeStamp(dateFilter.to, true),
			});
			const data = statsRes?.data?.data || [];
			const dataMap = {};
			data.forEach((d) => {
				const country = Countries.find((c) => c.alpha2_code === d.country);
				const countryName = country ? country.name : d.country;
				dataMap[countryName] = dataMap[countryName] || 0;
				dataMap[countryName] += d.count;
			});

			setCountryDistData({
				series: Object.values(dataMap),
				labels: Object.keys(dataMap),
			});

			setCountryDistLoading(false);
		} catch (err) {
			console.error(err);
			setCountryDistLoading(false);
		}
	};

	useEffect(() => {
		if (state?.medias?.length > 0) {
			getStats();
			getClickTimeDist();
			getCountryDist();
		} else {
			setClickDistLoading(false);
			setStatsLoading(false);
			setCountryDistLoading(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.medias, dateFilter]);

	const renderMedias = ({ original }) => (
		<MediaTable
			original={original}
			medias={state.medias}
			stats={stats}
			resized={resized}
			fetchingInfluencers={state.loading}
		/>
	);

	const memoizedData = React.useMemo(() => state.sources.map((s) => {
		const source = s;
		const inf = state.influencers.find((i) => i.id === source.influencer_id) || {};
		source.influencer = inf;

		const infClickStats = stats.sources[source.id];

		source.cpe = '-';
		source.cpe_clean = '-';

		if (infClickStats) {
			const likeAndComments = (source.postMedias || []).reduce((acc, curr) => {
				let total = acc;
				total += curr?.data?.comment_count || 0;
				total += curr?.data?.likes_count || 0;
				return total;
			}, 0);

			const cost = source?.postMedias[0]?.cost || 0;
			const totalEng = likeAndComments + infClickStats.total;
			const engagementScore = source.influencer.engagement_score;
			const { totalRealFollower } = calculateFollowerCount(engagementScore, inf?.data?.follower_count);
			const authEngRate = totalRealFollower / inf?.data?.follower_count;
			source.cpe = cost && infClickStats.total
				? `$${(cost / (infClickStats.total + totalEng)).toFixed(2)}`
				: '-';

			source.eCpe = cost && infClickStats.clean
				? `$${(cost / (infClickStats.clean + authEngRate))}`
				: '-';
		}

		return source;
	}), [state.sources, stats.sources, state.influencers]);

	const memoizedColumns = React.useMemo(() => InfluencerColumns({
		medias: state.medias,
		isSource: true,
		clickStats: stats.sources,
		onExpandedChange: (index) => {
			setExpandedState((es) => es[index] ? {} : { [index]: true });
		},
	}), [state.medias, stats.sources]);

	if (state.loading || statsLoading) return <Loader />;

	const susClickHover = (() => {
		if (flagReasonData?.labels.length === 0) return null;

		const clickDistArr = [...flagReasonData.labels].map((label, index) => {
			return `${label}: ${flagReasonData.series[index]}`;
		});

		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{clickDistArr.map((d) => <span>{d}</span>)}
			</div>
		);
	})();

	return (
		<div className="campaign-detail">
			<PageTitle>
				<div>
					<Breadcrumbs>
						<Breadcrumbs.Crumb><Link to="/campaign-list">Campaign List</Link></Breadcrumbs.Crumb>
						<Breadcrumbs.Crumb><Link to={`/campaign/${state.campaign?.id}`}>{state.campaign?.name}</Link></Breadcrumbs.Crumb>
					</Breadcrumbs>
					<PageTitle.Title>
						<span>{`Campaign Detail - ${state.campaign?.name || ''}`}</span>
					</PageTitle.Title>
				</div>
				<PlanDetail />
			</PageTitle>

			<FilterBox>
				<span />

				<FilterBox.Right>
					<DatePicker
						value={dateFilter}
						onChange={(dateObject) => {
							setDateFilter(dateObject);
							setStoreItem('start_date', dateObject.from);
							setStoreItem('end_date', dateObject.to);
						}}
					/>
				</FilterBox.Right>
			</FilterBox>

			<div className="influencer-list">
				{state.totalStats && (
					<StatsBox
						clickDistHover={susClickHover}
						data={{
							...state.totalStats,
							totalClicks: stats.campaign,
						}}
						showAuthEngRate
						authEngRate={state.campaign ? state.campaign.authEngRate : null}
					/>
				)}
				<h4 className="detail-section-title">Influencer</h4>
				<Box title="Influencers" className="box-table-wrapper">
					<Table
						data={memoizedData}
						columns={memoizedColumns}
						getTrGroupProps={(ss, rowInfo) => {
							if (rowInfo && expandedState[rowInfo.viewIndex]) {
								return ({
									className: '-open',
								});
							}
							return null;
						}}
						SubComponent={renderMedias}
						expanded={expandedState}
						resized={resized}
						onResizedChange={setResized}
						pageSize={1000}
						PadRowComponent={() => null}
						sortable={false}
						defaultSortDesc
						showPageSizeOptions={false}
						showPageJump={false}
						showPagination={false}
						loading={state.loading}
					/>
				</Box>
			</div>

			{state.sources.flatMap((source) => source.postMedias || []).length > 0 && (
				<div className="campaign-detail-posts">
					<h4 className="detail-section-title">Content</h4>
					<Box
						title="TOP 5 POSTS"
						className="post-list-wrapper"
						right={(
							<Fragment>
								<span className="box-title-dropdown-prefix">Order by</span>
								<Select
									mini
									label=""
									options={[
										{
											value: 'engagement',
											label: 'Engagement Rate',
										},
										{
											value: 'reach',
											label: 'Potential Reach',
										},
										{
											value: 'like',
											label: 'Like',
										},
										{
											value: 'comment',
											label: 'Comment',
										},
									]}
									value={[postSorting]}
									clearable={false}
									onChange={([val]) => setPostSorting(val)}
									renderSearch={() => null}
								/>
							</Fragment>
						)}
					>
						<div className="post-list">
							{state.sources
								.flatMap((source) => source.postMedias || [])
								.map((p) => {
									const post = p;
									const likeComment = post.data.likes_count + post.data.comment_count;
									const followers = post.influencer.data.follower_count;
									post.engagement = (likeComment > 0 && followers > 0) ? (likeComment / followers) * 100 : 0;
									const { totalRealFollower } = calculateFollowerCount(post?.influencer?.audience_score || {}, followers);
									post.reach = calculateEstimatedReach({
										posts: [post.data],
										realFollowers: totalRealFollower,
									});
									return post;
								})
								.sort((a, b) => {
									if (postSorting === 'engagement') {
										return b.engagement - a.engagement;
									}
									if (postSorting === 'reach') {
										return b.reach - a.reach;
									}
									if (postSorting === 'like') {
										return b.data.likes_count - a.data.likes_count;
									}
									if (postSorting === 'comment') {
										return b.data.comment_count - a.data.comment_count;
									}

									return b.data.ts_created - a.data.ts_created;
								})
								.slice(0, 5)
								.map((post) => (
									<InstagramPost
										key={post.data.id}
										id={post.data.id}
										type={post.data.type}
										captions={post.data.captions}
										ts_created={post.data.ts_created}
										display_url={post.data.display_url}
										likes_count={post.data.likes_count}
										comment_count={post.data.comment_count}
										accessibility_caption={post.data.accessibility_caption}
										profile_pic_url={post.influencer.data.profile_pic_url}
										username={post.influencer.data.username}
										reach={post.reach}
										engagement={post.engagement}
									/>
								))}
						</div>
					</Box>
				</div>
			)}

			<h4 className="detail-section-title">Story</h4>
			<div className="campaign-detail-line-chart">
				<Chart
					title="Click Time Distribution"
					series={clickTimeDistData}
					loading={clickDistLoading}
					right={<span style={{ fontSize: '12px' }}>UTC</span>}
					type="line"
					height="400"
					fullWidth
					getOptions={(options) => ({
						...options,
						annotations: {
							xaxis: [{
								x: `${String(new Date().getUTCHours())}`,
								strokeDashArray: 0,
								borderColor: '#B9CDE1',
								label: {
									borderColor: '#B9CDE1',
									style: {
										color: '#fff',
										background: '#B9CDE1',
									},
									text: 'Current Hour',
								},
							}],
						},
						grid: {
							xaxis: {
								lines: {
									show: true,
								},
							},
						},
						dataLabels: {
							enabled: false,
						},
						xaxis: {
							type: 'category',
							title: {
								text: 'Hours',
							},
							labels: {
								formatter: (val) => val < 10 ? `0${val}` : val,
							},
						},
						tooltip: {
							followCursor: false,
							theme: 'dark',
							x: {
								show: true,
								formatter: (val) => `Hour: ${val}:00`,
							},
							marker: {
								show: true,
							},
						},
						yaxis: [
							{
								...options.yaxis,
								min: 0,
								seriesName: 'Counts',
								forceNiceScale: true,
								title: {
									text: 'Counts',
								},
								labels: {
									minWidth: 50,
								},
							},
						],
						markers: {
							size: 0,
							style: 'full',
						},
						plotOptions: {
							line: {
								curve: 'smooth',
							},
						},
					})}
				/>
			</div>

			<Grid fluid>
				<Row>
					<Col xs={12} lg={6}>
						<Chart
							title="Country Distribution"
							type="pie"
							width="500"
							series={countryDistData.series}
							loading={countryDistLoading}
							getOptions={(options) => ({
								...options,
								labels: countryDistData.labels,
								legend: {
									width: 200,
									fontSize: '14px',
								},
								dataLabels: {
									enabled: true,
									textAnchor: 'middle',
								},
							})}
						/>
					</Col>
					<Col xs={12} lg={6}>

						<Chart
							title="Suspicious Click by Influencers"
							type="pie"
							width="500"
							loading={statsLoading}
							series={sClickDistData.series}
							getOptions={(options) => ({
								...options,
								labels: sClickDistData.labels,
								legend: {
									width: 200,
									fontSize: '14px',
								},
							})}
						/>

					</Col>

					<Col xs={12} lg={6}>
						<Chart
							title="Suspicious Click Reasons"
							type="pie"
							width="500"
							loading={statsLoading}
							series={flagReasonData.series}
							getOptions={(options) => ({
								...options,
								labels: flagReasonData.labels,
								legend: {
									width: 200,
									fontSize: '14px',
								},
							})}
						/>
					</Col>

					<Col xs={12} lg={6}>
						<Chart
							title="Click Distribution by Influencers"
							type="pie"
							width="500"
							loading={statsLoading}
							series={clickDistData.series}
							getOptions={(options) => ({
								...options,
								labels: clickDistData.labels,
								legend: {
									width: 200,
									fontSize: '14px',
								},
							})}
						/>
					</Col>
				</Row>
			</Grid>
		</div>
	);
}

// TODO: normal auth eng rate etkle infe