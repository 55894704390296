import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'interceptd-ui';

import calculatingGif from '../../assets/calculating.gif';

import './Calculating.css';

function Calculating({ tooltipPosition }) {
	return (
		<div className="calculating-animation">
			<Tooltip tooltip="This metric is still being calculated. This may take up to 2 hours. Please refresh the page." position={tooltipPosition}>
				<img src={calculatingGif} alt="calculating..." />
			</Tooltip>
		</div>
	);
}

Calculating.propTypes = {
	tooltipPosition: PropTypes.string,
};

Calculating.defaultProps = {
	tooltipPosition: 'bottom',
};

export default memo(Calculating);
