import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import TetherComponent from 'react-tether';
import { Modal } from 'interceptd-ui';

import useSilo from '../../services/use-silo';
import useLocal from '../../services/use-local';

import LimitIcon from '../../assets/limit.svg';

import './PlanDetail.css';

export default function PlanDetail() {
	const { silo } = useSilo();
	const { store, setStoreItem } = useLocal();
	const [isOpen, setIsOpen] = useState(false);
	const [redirectPath, setRedirectPath] = useState(null);

	if (redirectPath) {
		return <Redirect to={redirectPath} />;
	}

	const {
		inf_limit,
		post_limit,
		story_limit,
	} = silo?.currentPlan.limits;
	const planName = silo?.currentPlan?.name;
	const totalInfluencerCount = silo?.data?.influencers.length;
	const totalPostCount = silo?.data?.medias.filter((m) => m.type !== 'link').length;
	const totalStoryCount = silo?.data?.medias.length - totalPostCount;

	const influencerLeft = Math.max(0, inf_limit - totalInfluencerCount);
	const postLeft = Math.max(0, post_limit - totalPostCount);
	const storyLeft = Math.max(0, story_limit - totalStoryCount);

	const showWarningDot = influencerLeft < 2 || postLeft < 2 || storyLeft < 2;

	return (
		<TetherComponent
			attachment="bottom right"
			targetAttachment="top right"
			constraints={[
				{
					to: 'scrollParent',
					attachment: 'together',
				},
			]}
			renderTarget={(ref) => (
				<div
					ref={ref}
					className="plan-detail"
					onMouseEnter={() => setIsOpen(true)}
					onMouseLeave={() => setIsOpen(false)}
				>
					<div className="plan-detail-icon">
						<img src={LimitIcon} alt="icon" /> <span>{isOpen ? <Link to="/account/plans">See Other Plans</Link> : `${planName} Plan`}</span>
						{showWarningDot && <span className="plan-detail-warn-dot" />}
					</div>
					<Modal
						open={!store?.hideLimitWarningModal && showWarningDot}
						onCancel={() => {
							setStoreItem('hideLimitWarningModal', true);
						}}
						onAccept={() => setRedirectPath('/account/plans')}
						defaultAcceptText="Go to Plans - Got It"
						defaultCancelText="Do not show this again"
					>
						Your limits are almost full. You can change your subscription plan to Growth.
					</Modal>
				</div>
			)}
			renderElement={(ref) => isOpen && (
				<div ref={ref} className="plan-detail-icon-tooltip" onMouseLeave={() => setIsOpen(false)}>
					<div className="plan-detail-icon-tooltip-header">
						<span>{planName} Plan</span>
					</div>
					<div className={`plan-detail-icon-tooltip-row ${influencerLeft < 2 ? 'low-limit-level' : ''}`}>
						<span className="plan-detail-icon-tooltip-title">Influencer:</span>
						<span className="plan-detail-icon-tooltip-data">{totalInfluencerCount} of {inf_limit}</span>
					</div>
					<div className={`plan-detail-icon-tooltip-row ${postLeft < 2 ? 'low-limit-level' : ''}`}>
						<span className="plan-detail-icon-tooltip-title">Post:</span>
						<span className="plan-detail-icon-tooltip-data">{totalPostCount} of {post_limit}</span>
					</div>
					<div className={`plan-detail-icon-tooltip-row ${storyLeft < 2 ? 'low-limit-level' : ''}`}>
						<span className="plan-detail-icon-tooltip-title">Link:</span>
						<span className="plan-detail-icon-tooltip-data">{totalStoryCount} of {story_limit}</span>
					</div>
					{/* <div className="plan-detail-icon-tooltip-footer">
						<Link to="/account/plans">See Other Plans</Link>
					</div> */}
				</div>
			)}
		/>
	);
}
