import React from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Input } from 'interceptd-ui';
import { misc } from 'interceptd-ui/dist/utils';

import Button from '../../components/Button';

import Sidebar from './components/Sidebar';
import Header from './components/Header';

import Api from '../../services/api';
import useLocal from '../../services/use-local';

import './styles/Login.css';

export default function ForgotPassword() {
	const [email, setEmail] = React.useState('');
	const [fetching, setFetching] = React.useState(false);
	const { setStoreItem, resetStore } = useLocal();

	React.useEffect(() => {
		resetStore();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (fetching) return false;

		setFetching(true);
		try {
			await Api.forgotPassword({ email });
			setStoreItem('email', email);
			setFetching(false);
			toast.success('Password reset mail sent');
		} catch ({ response }) {
			setFetching(false);
			const responseMessage = response?.data?.error?.message;
			const message = typeof responseMessage === 'string'
				? responseMessage
				: 'Something went wrong.';
			toast.error(message);
		}

		return false;
	};

	const handleUsername = (event) => {
		setEmail(event.target.value);
	};

	return (
		<div className="login">
			<Helmet>
				<title>Influencer Dashboard - Forgot Password</title>
			</Helmet>
			<div className="login-wrapper">
				<Sidebar />
				<div className="login-right">
					<Header />
					<form className="login-form" onSubmit={handleSubmit}>
						<div className="login-title">
							<h1>Forgot Password</h1>
							<h2>We will send reset password link to your e-mail</h2>
						</div>
						<div className="login-form-inner">
							<Input
								type="email"
								name="email"
								placeholder="john@doe.com"
								label="Email Address"
								value={email}
								onChange={handleUsername}
							/>
							<Button type="submit" block loading={fetching} disabled={!misc.validateEmail(email)}>Reset Password</Button>
							<Button to="/welcome" block bgColor="transparent">Did you remember? Go to Sign In</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
