import React from 'react';
import PropTypes from 'prop-types';
import Humanize from 'humanize-plus';
import isEqual from 'lodash/isEqual';

import './StatsCell.css';

function StatsCell({ data }) {
	if (!data) {
		return <span className="stats-cell-na">N/A</span>;
	}

	const total = Humanize.compactInteger(data?.total || 0);
	const clean = Humanize.compactInteger(data?.clean || 0);
	const flags = Humanize.compactInteger(data?.flags || 0);

	return (
		<div className="stats-cell-data">
			<span className="stats-cell-data-value">{total}</span>
			<div className="stats-cell-data-footer">
				<span className="stats-cell-data-footer-value clean">{clean}</span>
				<span className="stats-cell-data-footer-text">clean</span>
				<span className="stats-cell-data-footer-value suspicious">{flags}</span>
				<span className="stats-cell-data-footer-text">suspicious</span>
			</div>
		</div>
	);
}

StatsCell.propTypes = {
	data: PropTypes.shape(),
};

StatsCell.defaultProps = {
	data: null,
};

export default React.memo(
	StatsCell,
	(prevProps, nextProps) => isEqual(prevProps, nextProps),
);
