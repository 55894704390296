import React from 'react';
import { ReactTableDefaults } from 'react-table';
import { StickyContainer } from 'react-sticky';

const StickyTable = (props) => (
	<StickyContainer>
		<ReactTableDefaults.TableComponent {...props} />
	</StickyContainer>
);

export default React.memo(StickyTable);
