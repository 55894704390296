import React from 'react';
import { AuthSidebar } from 'interceptd-ui';

import './Sidebar.css';

export default function Sidebar() {
	return (
		<AuthSidebar
			title={(
				<React.Fragment>
					<b>Influencer Audience Quality Evaluation</b>
					<span>Evaluate your influencers’ audience quality that detects suspicious followers and engagement </span>
					<b>Click and Engagement Tracking</b>
					<span>Measure click and engagement performances by using influencer-specific tracking links</span>
					<b>Live Campaign Fraud Monitoring</b>
					<span>Monitor suspicious activities, traffic quality and country distribution on your live campaigns</span>
				</React.Fragment>
			)}
			subtitle=""
			content=""
			Animation={() => null}
		/>
	);
}
