import axios from 'axios';

import config from './config.json';
import history from './history';
import { getFromLocal } from './use-local';

class Api {
	constructor() {
		this.url = window.location.hostname;

		this.api = axios.create({
			baseURL: config[this.url].endpoint,
			headers: {
				'Content-Type': 'application/json',
				Authorization: getFromLocal('token'),
			},
		});

		this.api.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error.response) {
					if (error.response.status === 401 || error.response.status === 403) {
						if (getFromLocal('token')) {
							this.api.defaults.headers.Authorization = null;
							history.push('/welcome');
						} else {
							this.api.defaults.headers.Authorization = null;
						}
					}
				}
				return Promise.reject(error);
			},
		);
	}
	setToken(token) {
		this.api.defaults.headers.Authorization = token;
	}

	/**
	 * General
	 */
	login(payload) {
		return this.api.post('/doberman/login', payload);
	}
	register(payload) {
		return this.api.post('/doberman/register', {
			...payload,
			product: 'influencer',
		});
	}
	forgotPassword(payload) {
		return this.api.post('/doberman/forgot-password', {
			...payload,
			product: 'influencer',
		});
	}
	resetPassword(token, payload) {
		return this.api.post('/doberman/reset-password', payload, {
			headers: {
				Authorization: token,
			},
		});
	}
	verifyUser(token) {
		return this.api.get(`/doberman/verify-email?token=${token}`);
	}
	resendVerifyEmail(payload) {
		return this.api.post('/doberman/resend-verification-email', payload);
	}
	getMe() {
		return this.api.get('/doberman/user/me');
	}
	updateAccount(payload) {
		return this.api.post('/doberman/update-user', payload);
	}
	deleteAccount() {
		return this.api.post('/doberman/user/delete');
	}
	updateUserSettings(payload) {
		return this.api.post('/doberman/user/settings', {
			...payload,
			product: 'influencer',
		});
	}
	updateAccountSettings(payload) {
		return this.api.put('/doberman/update-account', payload);
	}

  /**
   * Payment
   */
	getPlans() {
		return this.api.get('/doberman/payment/plans');
	}
	paymentSubscribe(payload) {
		return this.api.post('/doberman/payment/subscription', payload);
	}
	changePlan(payload) {
		return this.api.put('/doberman/payment/subscription', payload);
	}
	getPaymentMethods() {
		return this.api.get('/doberman/payment/methods');
	}
	getInvoices() {
		return this.api.get('doberman/payment/invoices');
	}
	getActivePromoCodes() {
		return this.api.get('/doberman/payment/coupons/count');
	}
	checkPromoCode(code) {
		return this.api.get(`doberman/payment/coupons?id=${code}`);
	}
	applyPromoCode(coupon) {
		return this.api.post('/doberman/payment/customer/coupon', {
			coupon,
		});
	}
	getStripeCustomer() {
		return this.api.get('/doberman/payment/customer');
	}

	/**
	 * Influencer
	 */
	getInfluencer(params) {
		return this.api.get('/domuz/influencers', { params });
	}
	addInfluencers(payload) {
		return this.api.post('/domuz/user-influencer', payload);
	}
	getUserInfluencers(params) {
		return this.api.get('/domuz/user-influencer', { params });
	}
	removeUserInfluencers(params) {
		return this.api.delete('/domuz/user-influencer', { params });
	}

	/**
	 * Campaign
	 */
	getCampaigns(params) {
		return this.api.get('/domuz/campaigns', { params });
	}
	getCampaign(id, params) {
		return this.api.get(`/domuz/campaigns/${id}`, { params });
	}
	createCampaign(payload) {
		return this.api.post('/domuz/campaigns', payload);
	}
	updateCampaign(id, payload) {
		return this.api.put(`/domuz/campaigns/${id}`, payload);
	}

	/**
	 * Source
	 */
	getSources(params) {
		return this.api.get('/domuz/sources', { params });
	}
	getSource(id) {
		return this.api.get(`/domuz/sources/${id}`);
	}
	createSource(payload) {
		return this.api.post('/domuz/sources', payload);
	}
	updateSource(id, payload) {
		return this.api.put(`/domuz/sources/${id}`, payload);
	}

	/**
	 * Media
	 */
	getMedias(params) {
		return this.api.get('/domuz/medias', { params });
	}
	getMedia(id) {
		return this.api.get(`/domuz/medias/${id}`);
	}
	createMedia(payload) {
		return this.api.post('/domuz/medias', payload);
	}
	updateMedia(id, payload) {
		return this.api.put(`/domuz/medias/${id}`, payload);
	}

	/**
	 * Stats
	 */
	getStats(params) {
		return this.api.get('/domuz/stats', { params });
	}
}

export default new Api();
