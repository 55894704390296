import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	PageTitle,
	StepsNav,
	Alert,
} from 'interceptd-ui';

import Button from '../../components/Button';

import Step1 from './Step1';
import Step2 from './Step2';

import Api from '../../services/api';
import useSilo from '../../services/use-silo';

import './styles/Create.css';

const ViewRoute = ({
	component: Component,
	path,
	exact,
	...rest
}) => (
	<Route
		path={path}
		exact={exact}
		render={(props) => <Component {...props} {...rest} />}
	/>
);

ViewRoute.propTypes = {
	component: PropTypes.elementType.isRequired,
	path: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
	]).isRequired,
	exact: PropTypes.bool,
};

ViewRoute.defaultProps = {
	exact: true,
};

const Steps = [
	{
		to: '',
		text: 'Add Influencers',
	},
	{
		to: 'validate',
		text: 'Validate',
	},
];

export default function AddInfluencer({ match }) {
	const { silo } = useSilo();
	const history = useHistory();
	const [seenFirst, setSeenFirst] = useState(false);
	const [adding, setAdding] = useState(false);

	const [influencers, setInfluencers] = useState([]);

	const [activeStep, setActiveStep] = useState(0);

	const { params: { page } } = match;

	const validInfluencers = influencers.filter((i) => i.valid);
	const totalInfluencerCount = silo?.data?.influencers.length;
	const maxInfluencerLimit = silo?.currentPlan?.limits?.inf_limit;
	const leftInfluencerCount = maxInfluencerLimit - totalInfluencerCount;

	useEffect(() => {
		setActiveStep(Steps.findIndex((step) => (page || '') === step.to));
	}, [page]);

	const handleInfluencerChange = (value) => {
		setInfluencers((prevInfluencers) => ([
			...prevInfluencers.filter((inf) => value.some((v) => v === inf.name)),
			...(value
				.filter((v) => !prevInfluencers.some((inf) => v === inf.name))
				.map((v) => ({
					name: v,
					fetching: false,
					data: null,
					valid: true,
					error: null,
					retry: 0,
				}))
			),
		]));
	};

	const handleInfluencerData = (influencer) => {
		setInfluencers((prevInfluencers) => (
			prevInfluencers.map((inf) => inf.name === influencer.name ? ({
				...inf,
				...influencer,
			}) : inf)
		));
	};

	const isNextDisabled = (step) => {
		switch (step) {
		case '':
			return influencers.length === 0;
		case 'validate':
			return influencers
				.filter((influencer) => (
					!influencer.fetching
					&& influencer.data
					&& influencer.valid
				)).length === 0;
		default:
			return false;
		}
	};

	const save = async () => {
		setAdding(true);
		try {
			// Add influencers
			const infsToAdd = influencers
				.filter((influencer) => (
					!influencer.fetching
					&& influencer.data
					&& influencer.valid
					&& influencer.id
				));
			await Api.addInfluencers({ influencer_ids: infsToAdd.map((i) => i.id) });

			toast.success('New Influencers added to your account');
			setTimeout(() => {
				window.location.href = '/';
			}, 2000);
		} catch (error) {
			setAdding(false);
			toast.error('Something went wrong');
		}
	};

	return (
		<div className="campaign-create">
			<div className="campaign-create-header">
				<PageTitle>
					<div className="campaign-create-header-inner">
						<PageTitle.Title>Add Influencers</PageTitle.Title>
					</div>
				</PageTitle>
				<StepsNav active={activeStep} data-active={activeStep}>
					{(setProgressWidth) => Steps.map((step, index) => (
						<StepsNav.Step key={`step-${index}`} active={(page || '') === step.to} setProgressWidth={setProgressWidth}>
							<Link to={`/influencer-add${step.to === '' ? '' : '/'}${step.to}`}>{step.text}</Link>
						</StepsNav.Step>
					))}
				</StepsNav>
			</div>

			<ViewRoute
				exact
				path="/influencer-add"
				component={Step1}
				setSeenFirst={setSeenFirst}
				leftInfluencerCount={leftInfluencerCount}
				influencers={influencers.map((influencer) => influencer.name)}
				onChange={handleInfluencerChange}
			/>

			<ViewRoute
				exact
				path="/influencer-add/validate"
				component={Step2}
				seenFirst={seenFirst}
				influencers={influencers}
				setInfluencers={setInfluencers}
				onChange={handleInfluencerData}
			/>

			<div className="campaign-create-alert">
				<Alert showDot={false} showTitle={false}>
					{`You have ${leftInfluencerCount - validInfluencers.length} influencer${(leftInfluencerCount - validInfluencers.length) === 1 ? '' : 's'} left. You can check other subscription plans from Payment settings.`}
				</Alert>
			</div>
			{(leftInfluencerCount) > 0 ? (
				<div className="campaign-create-buttons">
					{activeStep !== 0 && (
						<Button
							mini
							disabled={adding}
							bgColor="transparent"
							onClick={() => history.push(`/influencer-add${Steps[activeStep - 1].to === '' ? '' : '/'}${Steps[activeStep - 1].to}`)}
						>
							Prev
						</Button>
					)}
					<Button
						mini
						bgColor="shade"
						loading={adding}
						disabled={isNextDisabled(Steps[activeStep].to)}
						onClick={() => (activeStep === Steps.length - 1
							? save()
							: history.push(`/influencer-add${Steps[activeStep + 1].to === '' ? '' : '/'}${Steps[activeStep + 1].to}`))}
					>
						{activeStep === 0 ? 'Start' : activeStep === Steps.length - 1 ? 'Done' : 'Next'}
					</Button>
				</div>
			) : (
				<div className="campaign-create-buttons">
					<Button
						mini
						bgColor="shade"
						component={Link}
						to="/account/plans"
					>
						Plans
					</Button>
				</div>
			)}
		</div>
	);
}

AddInfluencer.propTypes = {
	match: PropTypes.shape().isRequired,
};
