export default {
	'Influencer List Informations': {
		'Audience Type': '​It indicates the classification of the followers of the influencer.',
		'Qualified Audience': '​It indicates the number of Real, Business and Influencer Accounts among the total followers, omitting the suspicious accounts. The higher the qualified audience the more the number of qualified accounts your campaign will be delivered to.',
		'Audience Authenticity': 'It indicates the percentage of Real, Business and Influencer Accounts, as those types are considered to be real profiles. The lower the authentic audience the higher the chances of suspicious followers among the audience.',
		'Estimated Reach': '​It indicates the estimated number of people who might see the post of the influencer.',
		'Audience Reachability': '​It indicates the percentage of followers among the audience who might see the post of the influencer in their feed. It excludes mass followers who follow more than 1,500 accounts and are less likely to see the post in their feed.',
		'Engagement Rate': 'It indicates the percentage of the followers engaging with the content of the influencer in its recent posts. On the dashboard you can also compare this metric with industry standards. Also check below to see all industry standards.',
		'Authentic Engagement Rate': 'It indicates the percentage of the engagement that is received from Real, Business and Influencer Accounts. It excludes suspicious engagements. The lower the authentic engagement rate the higher the changes of fake engagements. On the dashboard you can also compare this metric with industry standards. Also check below to see all industry standards.',
		'Average Likes': 'I​ t indicates the average number of likes the influencer receives in its recent posts.',
		'Average Comments': '​It indicates the average number of comments the influencer receives in its recent posts.',
		'Comment Rate': '​It indicates the percentage of the followers commenting to the content of the influencer in its recent posts.',
	},
	'Campaign List & Campaign Detail Informations': {
		'Health Score': 'This bar shows how well your campaign is performing regarding clean and suspicious clicks.',
		Links: '​This will indicate the number of tracking links that your campaign has.',
		Clicks: '​This metric shows the number of clicks that your campaign’s tracking link/s had. Below you may find a clear and suspicious amount of clicks.',
		Posts: 'This column shows you how many posts that influencers posted with the hashtags and mentions you’ve chosen in campaign create.',
		'Engagement Rate': '​It indicates the percentage of the followers engaging with the selected content of the influencer. Those posts will include campaign’s mentions, hashtags or both.',
		'Authentic Engagement Rate': 'It indicates the percentage of the engagement that is received from Real, Business and Influencer Accounts. It excludes suspicious engagements. The lower the authentic engagement rate the higher the chances of fake engagements.',
		'Estimated Reach': 'It indicates the estimated number of people who might see the campaign related posts of the influencer. Those posts will include campaign’s mentions, hashtags or both.',
		CPE: '​The cost you are willing to pay per engagement. This cost metric is getting calculated by the system only if you type the influencers’ costs while creating a campaign.',
	},
	'Industry Averages for Engagement Rates (based on Follower counts)': {
		'1K< # Followers <10K:': '4.4%',
		'10K < # Followers <100K': '2.4%',
		'100K< # Followers <1M': '1.8%',
		'1M< # Followers': '0.7%',
	},
};
