import React, {
	useEffect,
	useReducer,
	createContext,
	useContext,
} from 'react';
import PropTypes from 'prop-types';

import config from './config.json';

const storeKey = config[window.location.hostname].store_key;

const SET_ITEM = 'SET_ITEM';
const SET_STORE = 'SET_STORE';
const StoreContext = createContext();
const initialState = {};

const storeReducer = (state, action) => {
	switch (action.type) {
	case SET_ITEM:
		return {
			...state,
			[action.key]: action.payload,
		};
	case SET_STORE:
		return action.payload;
	default:
		return state;
	}
};

const init = (initialValue) => {
	try {
		const prevStore = window.localStorage.getItem(storeKey);
		return prevStore ? JSON.parse(prevStore) : initialValue;
	} catch (error) {
		return initialValue;
	}
};

export function StoreProvider({ children }) {
	const [state, dispatch] = useReducer(storeReducer, initialState, init);

	return (
		<StoreContext.Provider value={[state, dispatch]}>
			{children}
		</StoreContext.Provider>
	);
}

StoreProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default function useLocal() {
	const [state, dispatch] = useContext(StoreContext);

	useEffect(() => {
		window.localStorage.setItem(storeKey, JSON.stringify(state));
	}, [state]);

	const setStoreItem = (key, value) => dispatch({
		type: SET_ITEM,
		key,
		payload: value,
	});

	const removeStoreItem = (key) => dispatch({
		type: SET_ITEM,
		key,
		payload: undefined,
	});

	const resetStore = (value) => dispatch({
		type: SET_STORE,
		payload: value || initialState,
	});

	return {
		store: state,
		setStoreItem,
		removeStoreItem,
		resetStore,
	};
}

export function getFromLocal(key) {
	try {
		const prevStore = window.localStorage.getItem(storeKey);
		const store = prevStore ? JSON.parse(prevStore) : {};
		return store?.[key];
	} catch (error) {
		return null;
	}
}
