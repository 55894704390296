export const audienceTypeDescription = 'It indicates the classification of the followers of the influencer.';
export const qualifiedAudienceDescription = 'It indicates the number of Real, Business and Influencer Accounts among the total followers, omitting the suspicious accounts. The higher the qualified audience the more the number of qualified accounts your campaign will be delivered to.';
export const audienceAuthenticityDescription = 'It indicates the percentage of Real, Business and Influencer Accounts, as those types are considered to be real profiles. The lower the authentic audience the higher the chances of suspicious followers among the audience.';
export const estimatedReachDescription = 'It indicates the estimated number of people who might see the post of the influencer.';
export const audienceReachabilityDescription = 'It indicates the percentage of followers among the audience who might see the post of the influencer in their feed. It excludes mass followers who follow more than 1,500 accounts and are less likely to see the post in their feed. High engagement rate may help posts to appear in the Discovery tab resulting high reachability.';
export const authenticEngagementRateDescription = 'It indicates the percentage of the engagement that is received from Real, Business and Influencer Accounts. It excludes suspicious engagements. The lower the authentic engagement rate the higher the chances of fake engagements.';
export const averageLikesDescription = 'It indicates the average number of likes the influencer receives in its last 12 posts.';
export const averageCommentsDescription = 'It indicates the average number of comments the influencer receives in its last 12 posts.';
export const commentRateDescription = 'It indicates the percentage of the followers commenting to the content of the influencer in its last 12 posts.';
export const engagementRateDescription = 'It indicates the percentage of the followers engaging with the content of the influencer in its last 12 posts.';
