import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from 'interceptd-ui';

import Button from '../../components/Button';

import Sidebar from './components/Sidebar';
import Header from './components/Header';

import Api from '../../services/api';
import useLocal from '../../services/use-local';

import './styles/Login.css';

export default function ResetPassword({ match }) {
	const [form, setForm] = React.useState({
		plainPassword: {
			first: '',
			second: '',
		},
	});
	const [error, setError] = React.useState(false);
	const [disabled, setDisabled] = React.useState(true);
	const [fetching, setFetching] = React.useState(false);
	const [redirect, setRedirect] = React.useState(false);
	const { resetStore } = useLocal();

	React.useEffect(() => {
		resetStore();
		Api.setToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const {
			plainPassword,
		} = form;

		setError((plainPassword.first !== '' || plainPassword.second !== '') && plainPassword.first !== plainPassword.second);
		setDisabled(plainPassword.first === '' || plainPassword.second === '' || plainPassword.first !== plainPassword.second);
	}, [form]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (fetching) return false;

		setFetching(true);
		try {
			await Api.resetPassword(match.params.token, {
				password: form.plainPassword.first,
			});
			toast.success('Password changed');
			setRedirect(true);
		} catch (response) {
			setFetching(false);
			toast.error(response?.data?.error?.message || 'Something went wrong.');
		}

		return false;
	};

	const handlePassword = (e) => {
		const { name, value } = e.target;
		setForm((prevForm) => ({
			...prevForm,
			plainPassword: {
				...prevForm.plainPassword,
				[name]: value.trim(),
			},
		}));
	};

	if (redirect) return <Redirect to="/welcome" />;

	return (
		<div className="login">
			<Helmet>
				<title>Influencer Dashboard - Reset Password</title>
			</Helmet>
			<div className="login-wrapper">
				<Sidebar />
				<div className="login-right">
					<Header />
					<form className="login-form" onSubmit={handleSubmit}>
						<div className="login-title">
							<h1>Reset Password</h1>
							<h2>Set up your new password now</h2>
						</div>
						<div className="login-form-inner">
							<Input
								type="password"
								name="first"
								label="Password"
								value={form.plainPassword.first}
								onChange={(event) => handlePassword(event, 'first')}
							/>
							<Input
								type="password"
								name="second"
								label="Confirm Password"
								value={form.plainPassword.second}
								onChange={(event) => handlePassword(event, 'second')}
								error={error && 'Passwords are not same'}
							/>
							<Button type="submit" block loading={fetching} disabled={disabled}>Change Password</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

ResetPassword.propTypes = {
	match: PropTypes.shape().isRequired,
};
