import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import {
	PageTitle,
	Box,
	Modal,
	Loader,
	FilterBox,
	Input,
	Icon,
} from 'interceptd-ui';

import Button from '../../components/Button';
import Table from '../../components/Table';

import PaymentForm from './PaymentForm';

import useSilo from '../../services/use-silo';
import Api from '../../services/api';

import PaymentActivityColumns from './PaymentActivityColumns';

import './styles/Plans.css';

const stripeKey = (window.location.hostname === 'localhost' || window.location.hostname === 'influencer.qa.interceptd.com')
	? 'pk_test_OQjMkB93Sckwe5bwrgWrWGFM00WISMUVKc'
	: 'pk_live_sO0uGr2Z9vrYUbOWVHJ7SaJM00okbmyXyK';

const stripePromise = loadStripe(stripeKey);
const ELEMENTS_OPTIONS = {
	fonts: [
		{
			cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
		},
	],
};

const Plans = () => {
	const { silo, setSiloItem } = useSilo();
	const [formActive, setFormActive] = useState();
	const [plan, setPlan] = useState(null);
	const [loading, setLoading] = useState(false);
	const [invoiceFetching, setInvoiceFetching] = useState(true);
	const [invoices, setInvoices] = useState([]);
	const [planToBeUpdated, setPlanToBeUpdated] = useState(null);
	const [activeCodeCount, setActiveCodeCount] = useState(0);
	const [promoCode, setPromoCode] = useState(null);
	const [openPromoModal, setOpenPromoModal] = useState(false);
	const [promoCodeError, setPromoCodeError] = useState('');
	const [promoObject, setPromoObject] = useState(null);
	const [promoCodeIsChecking, setPromoCodeIsChecking] = useState(false);
	const [stripeCustomer, setStripeCustomer] = useState(false);
	const [isOldPromoCode, setIsOldPromoCode] = useState(false);
	const [currentPlan, setCurrentPlan] = useState({
		name: 'Trial',
		price: 0,
		limits: {},
	});

	// const influencerCount = silo?.data?.influencers.length;
	// const storyCount = silo?.data?.medias.filter((m) => m.type === 'link').length;
	// const postCount = silo?.data?.medias.length - storyCount;
	const planList = silo?.plans || {};
	currentPlan.name = silo?.currentPlan?.name;

	// const influencerLeft = Math.max(0, currentPlan.limits.inf_limit - influencerCount);
	// const storyLeft = Math.max(0, currentPlan.limits.story_limit - storyCount);
	// const postLeft = Math.max(0, currentPlan.limits.post_limit - postCount);

	useEffect(() => {
		getInvoices();
		getStripeCustomer();
	}, []);

	useEffect(() => {
		if (silo?.currentPlan) {
			setCurrentPlan(silo?.currentPlan);
		}
	}, [silo]);

	const getActiveCodeCount = () => {
		Api.getActivePromoCodes()
			.then((response) => {
				setActiveCodeCount(response?.data?.data?.valid_coupon_count || 0);
			})
			.catch((err) => console.error(err));
	};

	const getStripeCustomer = () => {
		Api.getStripeCustomer()
			.then((response) => {
				const currentCoupon = response?.data?.data?.discount?.coupon || null;
				setStripeCustomer(response?.data?.data);
				if (!currentCoupon) {
					getActiveCodeCount();
				} else {
					setPromoObject(currentCoupon);
					setIsOldPromoCode(true);
				}
			})
			.catch((err) => {
				getActiveCodeCount();
				console.error(err);
			});
	};

	const getInvoices = async () => {
		try {
			setLoading(true);
			const methodReady = Api.getPaymentMethods();
			const invoiceReady = Api.getInvoices();

			const methodResponse = await methodReady;
			const invoiceResponse = await invoiceReady;

			const methodList = methodResponse?.data?.data || [];
			const invoiceList = invoiceResponse?.data?.data || [];

			const [paymentMethod] = methodList;

			setInvoices(invoiceList.map((inv) => {
				const invoice = inv;
				invoice.card = paymentMethod?.card;
				return invoice;
			}));
			setInvoiceFetching(false);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			setInvoiceFetching(false);
			console.error(err);
		}
	};

	const selectPlan = (planName) => {
		const selectedPlan = planList[planName];
		selectedPlan.name = planName;
		setFormActive(true);
		setPlan(selectedPlan);
	};

	const boxRight = (planName) => {
		if (currentPlan?.name === planName) {
			return (
				<span className="current-plan">
					Current Plan
				</span>
			);
		}

		if (planName.toLowerCase() === 'trial') {
			return null;
		}

		if (currentPlan?.price > planList[planName].price) {
			// can't downgrade
			return null;
		}

		const isUpdatable = currentPlan.price !== 0 && (currentPlan?.price < planList[planName].price);

		return (
			<Button
				mini
				onClick={() => isUpdatable ? setPlanToBeUpdated(planName) : selectPlan(planName)}
			>
				{isUpdatable ? 'UPDATE' : 'SELECT'}
			</Button>
		);
	};

	const onSuccess = (newPlanName) => {
		const newPlan = planList[newPlanName];
		toast.success('Payment Successful');
		getInvoices();
		setFormActive(false);
		setSiloItem('currentPlan', {
			name: newPlanName,
			...newPlan,
		});
	};

	const applyPromoCode = () => {
		setPromoCodeIsChecking(false);
		Api.checkPromoCode(promoCode)
			.then((response) => {
				if (stripeCustomer && !stripeCustomer?.discount?.coupon) {
					Api.applyPromoCode(response.data.data.id)
						.then(() => {
							toast.success('New promo code applied successfully');
							setIsOldPromoCode(true);
						})
						.catch((err) => console.error(err));
				}
				setPromoObject(response.data.data);
				setPromoCodeError(null);
				setOpenPromoModal(false);
			})
			.catch((err) => {
				const errorMessage = err?.response?.data?.error?.message || 'Promo Code Is Not Valid!';
				setPromoCodeError(errorMessage);
				setPromoCodeIsChecking(false);
			});
	};

	const updatePlan = () => {
		if (planToBeUpdated === null) return;
		setLoading(true);

		Api.changePlan({
			plan: planToBeUpdated,
			product: 'influencer',
		}).then(() => {
			onSuccess(planToBeUpdated);
			setPlanToBeUpdated(null);
			setLoading(false);
			toast.success('You have successfully upgraded your payment plan. Next month you’ll be charged according to your new plan.', {
				autoClose: 6000,
			});
		}).catch(() => {
			toast.error('Couldn\'t update plan.');
			setLoading(false);
		});
	};

	return (
		<div className="plans-page">
			<PageTitle>
				<PageTitle.Title>Plans</PageTitle.Title>
			</PageTitle>

			<FilterBox>
				<span />
				<FilterBox.Right>
					{!promoObject && activeCodeCount > 0 && <Button mini onClick={() => setOpenPromoModal(true)}>Enter Promo Code</Button>}
					{promoObject && (
						<div className="promo-code-name">
							<span>Promo Code: <b>{promoObject.id}</b></span>
							{!isOldPromoCode && <Icon i="x-circle" size="15" onClick={() => setPromoObject(null)} />}
						</div>
					)}
				</FilterBox.Right>
			</FilterBox>

			{loading
				? <Loader />
				: (
					<div className="pricing-table">
						<div className="pricing">
							{Object.keys(planList).map((planName) => (
								<Box className={`pricing-box ${currentPlan?.name === planName ? 'current-plan' : ''}`} headerProps={{ style: { display: 'none' } }} key={planName}>
									<div className="pricing-box-title">
										<span className="pricing-box-name">{planName}</span>
										<span className="pricing-box-price">
											<span className={(promoObject && planList[planName].price !== 0) ? 'price-stripped' : ''}>
												${planList[planName].price === 0 ? planList[planName].price : `${planList[planName].price}/month`}
											</span>
											{(promoObject && planList[planName].price !== 0) && (
												<span>${(planList[planName].price - ((promoObject.percent_off / 100) * planList[planName].price)).toFixed(2)}/month</span>
											)}
										</span>
									</div>
									<div className="pricing-box-features">
										<div className="pricing-box-feature">
											<span>Influencer</span>
											<b>{planList[planName].limits.inf_limit}</b>
										</div>
										<div className="pricing-box-feature">
											<span>Link</span>
											<b>{planList[planName].limits.story_limit}</b>
										</div>
										<div className="pricing-box-feature">
											<span>Post</span>
											<b>{planList[planName].limits.post_limit}</b>
										</div>
									</div>
									<div className="pricing-box-footer">
										{boxRight(planName)}
									</div>
								</Box>
							))}
						</div>
					</div>
				)}

			<Box
				className="box-table-wrapper"
				title="Payment Activity"
			>
				<Table
					data={invoices}
					columns={PaymentActivityColumns()}
					pageSize={1000}
					PadRowComponent={() => null}
					sortable={false}
					defaultSortDesc
					showPageSizeOptions={false}
					showPageJump={false}
					showPagination={false}
					loading={invoiceFetching}
				/>
			</Box>

			<Modal
				open={planToBeUpdated !== null}
				title="Warning"
				onCancel={() => setPlanToBeUpdated(null)}
				onAccept={updatePlan}
				defaultAcceptText="Yes"
				renderFooter={loading ? () => <div className="loader-footer-modal"><Loader mini /></div> : null}
			>
				{`You’ll be upgrading your subscription plan to ${planToBeUpdated}. Do you want to proceed?`}
			</Modal>

			<Modal
				open={openPromoModal}
				title="Promo Code"
				modalClassName="promo-code-modal"
				defaultAcceptText="Apply"
				onAccept={applyPromoCode}
				onCancel={() => setOpenPromoModal(false)}
			>
				<div className="promo-code-form">
					<Input
						label="Promo Code"
						placeholder="Enter your code"
						onChange={({ target }) => setPromoCode(target.value)}
						value={promoCode}
						error={promoCodeError}
					/>
				</div>
			</Modal>

			<Modal
				open={formActive}
				title="Make Payment"
				modalClassName="payment-modal"
				acceptProps={{ style: { display: 'none' } }}
				onCancel={() => setFormActive(false)}
			>
				<div className="payment-form">
					<Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
						<PaymentForm
							coupon={promoObject?.id || null}
							plan={plan && {
								...plan,
								price: promoObject
									? (plan.price - ((promoObject.percent_off / 100) * plan.price)).toFixed(2)
									: plan.price,
							}}
							onSuccess={onSuccess}
						/>
					</Elements>
				</div>
			</Modal>
		</div>
	);
};

export default Plans;