import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
	Tooltip,
	Icon,
} from 'interceptd-ui';
import Humanize from 'humanize-plus';

import Calculating from '../../../components/Calculating';

function InstagramPost({
	display_url,
	likes_count,
	comment_count,
	accessibility_caption,

	profile_pic_url,
	username,

	reach,
	engagement,
}) {
	return (
		<div className="instagram-post">
			<header className="instagram-post-header">
				<div className="instagram-post-avatar">
					<img src={profile_pic_url} alt={username} />
				</div>
				<div className="instagram-post-username">
					<a href={`http://instagram.com/${username}`} target="_blank" rel="noopener noreferrer">{username}</a>
				</div>
			</header>
			<div className="instagram-post-image">
				<div className="instagram-post-image-container">
					<img src={display_url} alt={accessibility_caption} />
				</div>
			</div>
			<div className="instagram-post-info">
				<div className="instagram-post-info-likes">
					<Tooltip tooltip="Likes">
						<Icon i="heart" size={16} />
					</Tooltip>
					<span>{likes_count}</span>
				</div>
				<div className="instagram-post-info-comments">
					<Tooltip tooltip="Comments">
						<Icon i="message-circle" size={16} />
					</Tooltip>
					<span>{comment_count}</span>
				</div>
			</div>
			<div className="instagram-post-info">
				<div className="instagram-post-info-er">
					<Tooltip tooltip="Engagement Rate">
						<span className="icon">ER</span>
					</Tooltip>
					<span>{engagement ? `${engagement.toFixed(2)}%` : 'N/A'}</span>
				</div>
				<div className="instagram-post-info-r">
					<Tooltip tooltip="Potential Reach">
						<span className="icon">PR</span>
					</Tooltip>
					<span>{reach ? Humanize.compactInteger(reach, 2).toUpperCase() : <Calculating />}</span>
				</div>
			</div>
		</div>
	);
}

InstagramPost.propTypes = {
	display_url: PropTypes.string.isRequired,
	likes_count: PropTypes.number.isRequired,
	comment_count: PropTypes.number.isRequired,
	accessibility_caption: PropTypes.string.isRequired,
	profile_pic_url: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
	reach: PropTypes.string.isRequired,
	engagement: PropTypes.string.isRequired,
};

export default memo(InstagramPost);
