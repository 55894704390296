import React, { useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import {
	Box,
	Modal,
	Button,
	Icon,
} from 'interceptd-ui';

import Table from '../../components/Table';
import StatsBox from '../../components/StatsBox';

import TrackingURLModal from '../Modals/TrackingURLModal';

import Columns from './CampaignListColumns';

import useSilo from '../../services/use-silo';

import './styles/CampaignList.css';

function CampaignList({
	clickStats,
}) {
	const { silo } = useSilo();
	const [trackingModalOpen, setTrackingModalOpen] = useState(false);
	const [trackingModalCampaign, setTrackingModalCampaign] = useState();
	const [openEmptyInfModal, setOpenEmptyInfModal] = useState(false);
	const [redirectTo, setRedirectTo] = useState(null);
	const { data } = silo;

	const sumCampaignStats = (stats, camps) => {
		if (!camps) return {};

		const initialState = camps.reduce((acc, cur) => {
			acc[cur.id] = {
				flagged: 0,
				clean: 0,
				total: 0,
				flag_reasons: [],
			};
			return acc;
		}, {});

		return stats.reduce((acc, cur) => {
			if (!acc[cur.campaign_id]) return acc;
			if (cur.flag_reason === '') {
				acc[cur.campaign_id].clean += cur.count;
			} else {
				acc[cur.campaign_id].flagged += cur.count;
				acc[cur.campaign_id].flag_reasons.push(cur.flag_reason);
			}
			acc[cur.campaign_id].total += cur.count;
			return acc;
		}, initialState);
	};

	const totalStats = useMemo(() => (
		sumCampaignStats(clickStats, data.campaigns)
	), [clickStats, data.campaigns]);

	const memorizedColumns = useMemo(() => Columns({
		totalStats,

		sources: data.sources,
		medias: data.medias,

		setTrackingModalCampaign,
		setTrackingModalOpen,
	}), [data, totalStats]);

	const sumStats = (stats) => Object.values(stats).reduce((acc, cur) => {
		acc.total += cur.total;
		acc.clean += cur.clean;
		acc.flagged += cur.flagged;
		acc.flag_reasons = [
			...acc.flag_reasons,
			...cur.flag_reasons,
		];
		return acc;
	}, {
		total: 0,
		flagged: 0,
		clean: 0,
		flag_reasons: [],
	});

	const totalClicks = useMemo(() => (
		sumStats(totalStats)
	), [totalStats]);

	const handleCreateClick = () => {
		if (data?.influencers && data?.influencers?.length > 0) {
			setRedirectTo('/create');
		} else {
			setOpenEmptyInfModal(true);
		}
	};

	if (redirectTo) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<div className="campaign-list">
			<StatsBox
				hideHealthScore
				data={{
					totalSource: uniqBy(data.sources, 'influencer_id').length || 0,
					totalMedia: {
						total: data.medias.length || 0,
						link: data.medias.filter((media) => media.type === 'link').length || 0,
						post: data.medias.filter((media) => media.type !== 'link').length || 0,
						activeLink: data.medias.filter((media) => media.type === 'link').length || 0,
					},
					totalClicks: {
						total: totalClicks?.total || 0,
						flags: totalClicks?.flagged || 0,
						clean: totalClicks?.clean || 0,
					},
				}}
			/>
			<Box
				className="box-table-wrapper"
				title={(
					<Button
						mini
						onClick={handleCreateClick}
						className="add-new-campaign-button"
					>
						<Icon i="plus" size="15" /> Create Campaign
					</Button>
				)}
			>
				<Table
					data={data.campaigns}
					columns={memorizedColumns}
					loading={data.loading}
					pageSize={1000}
					stickyHeader
					defaultSortDesc
					PadRowComponent={() => null}
					sortable={false}
					showPageSizeOptions={false}
					showPageJump={false}
					showPagination={false}
				/>
			</Box>

			<Modal
				open={trackingModalOpen}
				icon={null}
				title="Campaign Tracking Links"
				acceptProps={{
					style: {
						display: 'none',
					},
				}}
				onCancel={() => {
					setTrackingModalOpen(false);
					setTrackingModalCampaign({});
				}}
				defaultCancelText="Close"
			>
				{trackingModalCampaign && (
					<TrackingURLModal
						campaign={trackingModalCampaign}
						sources={(
							data.sources
								.filter((source) => source.campaign_id === trackingModalCampaign.id)
								.map((source) => ({
									...source,
									data: data.influencers.find((influencer) => influencer.id === source.influencer_id),
									medias: data.medias.filter((media) => media.source_id === source.id),
								}))
						)}
					/>
				)}
			</Modal>

			<Modal
				open={openEmptyInfModal}
				onAccept={() => setRedirectTo('/influencer-add')}
				onCancel={() => setOpenEmptyInfModal(false)}
				defaultAcceptText="Add Influencer(s)"
				defaultCancelText="Cancel"
			>
				<span>
					You need to have influencer(s) in your list to create a campaign. Please add an influencer to proceed.
				</span>
			</Modal>
		</div>
	);
}

CampaignList.propTypes = {
	clickStats: PropTypes.arrayOf(PropTypes.shape()),
};

CampaignList.defaultProps = {
	clickStats: [],
};

export default React.memo(
	CampaignList,
	(prevProps, nextProps) => isEqual(prevProps.clickStats, nextProps.clickStats),
);
