/* eslint-disable react/prop-types */
import React from 'react';
import { toast } from 'react-toastify';
import {
	Actions,
	Icon,
	Copy,
} from 'interceptd-ui';
import { file } from 'interceptd-ui/dist/utils';

import StatsCell from '../../components/StatsCell';

const Columns = ({
	clickStats,
}) => [
	{
		Header: '',
		id: 'username',
		accessor: 'landing_page_url',
		cast: 'string',
		minWidth: 150,
		style: {
			overflow: 'visible',
		},
		sticky: true,
		stickyPosition: 'left',
		Cell: ({ value }) => (
			<div className="media-table-name">
				<span style={{ flex: 'none' }}><Icon i="corner-down-right" size={18} /></span>
				<Copy
					value={value}
					onCopy={() => toast.success('Link copied')}
					className="table-list-copy"
				>
					<Icon i="copy" size={15} /> {value}
				</Copy>
			</div>
		),
	},
	{
		Header: '',
		id: 'click',
		cast: 'number',
		sortable: true,
		minWidth: 130,
		accessor: (val) => clickStats[val.id]?.total || 0,
		Cell: ({ original }) => (
			<StatsCell
				data={clickStats[original.id]}
			/>
		),
	},
	{
		Header: null,
		id: 'link',
		cast: 'number',
		sortable: true,
		minWidth: 90,
		accessor: () => null,
	},
	{
		Header: null,
		id: 'post',
		cast: 'number',
		sortable: true,
		minWidth: 90,
		accessor: () => null,
	},
	{
		Header: null,
		id: 'engagement',
		cast: 'number',
		sortable: true,
		minWidth: 140,
		accessor: () => null,
	},
	{
		Header: null,
		id: 'authenticEngagement',
		cast: 'number',
		sortable: true,
		minWidth: 163,
		accessor: () => null,
	},
	{
		Header: null,
		id: 'cpe',
		cast: 'number',
		sortable: true,
		minWidth: 100,
		accessor: () => null,
	},
	{
		Header: null,
		id: 'estimatedReach',
		cast: 'number',
		sortable: true,
		minWidth: 55,
		accessor: () => null,
	},
	{
		Header: '',
		id: 'actions',
		accessor: 'id',
		cast: 'actions',
		minWidth: 65,
		resizable: false,
		sortable: false,
		sticky: true,
		headerStyle: {
			overflow: 'visible',
			justifyContent: 'center',
			padding: 0,
		},
		style: {
			overflow: 'visible',
			justifyContent: 'center',
			padding: 0,
		},
		Cell: ({ original }) => (
			<Actions>
				<Actions.Item
					onClick={() => {
						file.copyTextToClipboard(`https://trk.interceptd.com/inf-click?med=${original.external_id}`);
						toast.success('Link copied');
					}}
				>
					Copy Tracking Link
				</Actions.Item>
			</Actions>
		),
	},
];

export default Columns;
