import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Icon } from 'interceptd-ui';

import './SummaryBox.css';

function SummaryBox({
	className,
	title,
	editUrl,
	children,
	...rest
}) {
	const history = useHistory();

	return (
		<div className={`summary-box ${className || ''}`} {...rest}>
			{title && (
				<div className="summary-box-title">
					{title}
					{editUrl && <Icon i="edit-3" size={14} onClick={() => history.push(editUrl)} />}
				</div>
			)}
			<div className="summary-box-inner">
				{children}
			</div>
		</div>
	);
}

SummaryBox.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	editUrl: PropTypes.string,
	children: PropTypes.node.isRequired,
};

SummaryBox.defaultProps = {
	className: '',
	title: null,
	editUrl: null,
};

export default React.memo(SummaryBox);
