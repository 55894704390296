/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import Humanize from 'humanize-plus';
import {
	Actions,
	Info,
	Tooltip,
} from 'interceptd-ui';

import StatsCell from '../../components/StatsCell';

import { estimatedReachDescription } from '../../constants/descriptions';

export default ({
	totalStats = {},

	sources,
	medias,

	setTrackingModalCampaign,
	setTrackingModalOpen,
}) => [{
	Header: 'Name',
	id: 'name',
	accessor: 'name',
	cast: 'string',
	minWidth: 250,
	style: {
		overflow: 'visible',
	},
	Cell: ({ value, original }) => (
		<Link to={`/campaign/${original.id}`}>{value}</Link>
	),
},
{
	Header: 'Mentions & Hashtags',
	id: 'mention',
	accessor: 'mention',
	width: 200,
	resizable: false,
	sortable: false,
	style: {
		overflow: 'visible',
	},
	Cell: ({ value }) => {
		const mentions = value.filter((v) => v.startsWith('@'));
		const hashtags = value.filter((v) => v.startsWith('#'));
		const max = mentions.length > hashtags.length
			? mentions.length
			: hashtags.length;

		const mix = [];
		for (let i = 0; i < max; i += 1) {
			if (mentions[i]) mix.push(mentions[i]);
			if (hashtags[i]) mix.push(hashtags[i]);
		}

		const total = mix.length;
		const text = total > 2
			? mix.splice(0, 2)
			: mix;

		return (
			<div className="mention-col">
				{text.join(' ')}
				{total > 2 && (
					<Tooltip
						position="right"
						tooltip={(
							<div className="mention-list">
								<span className="mention-list-title">{`${mentions.length} mention`}</span>
								<span className="mention-list-content">{mentions.length > 0 ? mentions.join(' ') : '-'}</span>
								<span className="mention-list-title">{`${hashtags.length} hashtag`}</span>
								<span className="mention-list-content">{hashtags.length > 0 ? hashtags.join(' ') : '-'}</span>
							</div>
						)}
					>
						<span className="mention-list-tooltip-text">{mix.length} More</span>
					</Tooltip>
				)}
			</div>
		);
	},
},
{
	Header: 'Influencers',
	id: 'influencers',
	resizable: false,
	sortable: true,
	style: {
		overflow: 'visible',
	},
	minWidth: 120,
	accessor: (original) => sources.filter((source) => source.campaign_id === original.id).length,
},
{
	Header: 'Links',
	id: 'links',
	cast: 'number',
	sortable: true,
	accessor: (original) => medias.filter((media) => media.campaign_id === original.id && media.type === 'link').length,
},
{
	Header: 'Clicks',
	id: 'click',
	accessor: (original) => totalStats?.[original.id]?.total || 0,
	cast: 'number',
	sortable: true,
	minWidth: 200,
	Cell: ({ original }) => {
		const stats = totalStats[original.id];

		return (
			<StatsCell
				data={{
					total: stats.total,
					clean: stats.clean,
					flags: stats.flagged,
				}}
			/>
		);
	},
},
{
	Header: 'Posts',
	id: 'posts',
	cast: 'number',
	sortable: true,
	accessor: (original) => medias.filter((media) => media.campaign_id === original.id && media.type !== 'link').length,
},
{
	Header: 'Engagement Rate',
	id: 'engagement',
	accessor: 'engagementRate',
	cast: 'number',
	sortable: true,
	Cell: ({ value }) => value ? `${value}%` : <Tooltip position="left" tooltip="Metrics will be calculated once posts with your hashtags/mentions are live.">N/A</Tooltip>,
},
{
	Header: () => (
		<div className="column-header-with-info">
			<Info tooltipProps={{ position: 'left' }}>{estimatedReachDescription}</Info>
			<span>Potential Reach</span>
		</div>
	),
	id: 'estimatedReach',
	cast: 'number',
	sortable: true,
	accessor: 'estimatedReach',
	Cell: ({ value }) => value ? Humanize.compactInteger(value, 1).toUpperCase() : <Tooltip position="left" tooltip="Metrics will be calculated once posts with your hashtags/mentions are live.">N/A</Tooltip>,
},
{
	Header: '',
	id: 'actions',
	accessor: 'id',
	cast: 'actions',
	width: 65,
	resizable: false,
	sortable: false,
	sticky: true,
	headerStyle: {
		overflow: 'visible',
		justifyContent: 'center',
		padding: 0,
	},
	style: {
		overflow: 'visible',
		justifyContent: 'center',
		padding: 0,
	},
	Cell: ({ original }) => (
		<Actions>
			<Actions.Item
				onClick={() => {
					setTrackingModalCampaign(original);
					setTrackingModalOpen(true);
				}}
			>
				Copy Tracking Links
			</Actions.Item>
		</Actions>
	),
}];
