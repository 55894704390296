import Humanize from 'humanize-plus';
import isEqual from 'lodash/isEqual';

import { median } from '../misc';

export function calculateReach({ likes, comments, realFollowers }) {
	return (realFollowers * 0.52 + likes * 8.97 + comments * 334) / 3;
}

export function calculateEstimatedReach({ posts, realFollowers }) {
	try {
		const counts = posts.reduce((acc, curr) => {
			const reach = calculateReach({
				likes: curr.likes_count,
				comments: curr.comment_count,
				realFollowers,
			});

			acc.push(+reach.toFixed(1));
			return acc;
		}, []);

		const result = median(counts);
		return Math.round(result);
	} catch (err) {
		return 'N/A';
	}
}

export function calculateAudienceReachability({ posts, realFollowers, followers }) {
	try {
		const estimatedReach = calculateEstimatedReach({ posts, realFollowers });

		if (estimatedReach === 'N/A') {
			return estimatedReach;
		}

		return (estimatedReach * 100) / followers;
	} catch (err) {
		return 'N/A';
	}
}

export function calculateAuthenticEngagementScore(engagementScore, engagementRate) {
	if (isEqual(engagementScore, {})) {
		return null;
	}

	const {
		totalScoreRealFollower,
		totalScoreFollower,
	} = calculateFollowerCount(engagementScore);

	return (engagementRate * (totalScoreRealFollower / totalScoreFollower)).toFixed(2);
}

export function calculateAudienceScore(audienceScore, followerCount) {
	if (isEqual(audienceScore, {})) {
		return ({
			qualifiedAudience: '-',
			audienceAuthenticity: '-',
		});
	}

	const {
		totalScoreFollower,
		totalScoreRealFollower,
	} = calculateFollowerCount(audienceScore, followerCount);
	const qualifiedAudienceData = ((totalScoreRealFollower) / totalScoreFollower) * followerCount;

	const qualifiedAudience = Humanize.compactInteger(qualifiedAudienceData, 1).toUpperCase();
	const audienceAuthenticity = `${((totalScoreRealFollower / totalScoreFollower) * 100).toFixed(2)}%`;

	return ({
		qualifiedAudience,
		qualifiedAudienceData,
		audienceAuthenticity,
	});
}

export function calculateFollowerCount(score, followerCount) {
	const totalScoreFollower = Object.values(score).reduce((a, b) => a + b, 0);
	const totalScoreRealFollower = score.other + score.business + score.influencer;
	const totalRealFollower = (totalScoreRealFollower / totalScoreFollower) * followerCount;
	return ({
		totalScoreFollower,
		totalScoreRealFollower,
		totalRealFollower,
	});
}
