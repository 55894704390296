// Public Pages
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import LoginAs from './pages/Auth/LoginAs';
import Switch from './pages/Auth/Switch';
import Logout from './pages/Auth/Logout';
import ResetPassword from './pages/Auth/ResetPassword';
import ForgotPassword from './pages/Auth/ForgotPassword';
import Verify from './pages/Auth/Verify';
import Join from './pages/Auth/Join';

// Private Pages
import Plans from './pages/Plans';
import List from './pages/Campaign/List';
import CampaignDetail from './pages/Campaign/CampaignDetail';
import InfluencerList from './pages/Influencer/InfluencerList';
import InfluencerDetail from './pages/Influencer/InfluencerDetail';

import Create from './pages/CampaignCreate/Create';
import AddInfluencer from './pages/CampaignCreate/AddInfluencer';
import NotFound from './pages/NotFound';
import Glossary from './pages/Glossary';

export const publicRoutes = [
	{
		path: '/welcome',
		exact: true,
		component: Login,
	},
	{
		path: '/signup',
		exact: true,
		component: Register,
	},
	{
		path: '/login_as/:token',
		exact: true,
		component: LoginAs,
	},
	{
		path: '/switch/:token',
		exact: true,
		component: Switch,
	},
	{
		path: '/forgot-password',
		exact: true,
		component: ForgotPassword,
	},
	{
		path: '/password-reset/:token',
		exact: true,
		component: ResetPassword,
	},
	{
		path: '/verify/:code?',
		exact: true,
		component: Verify,
	},
	{
		path: '/join/:token?',
		exact: true,
		component: Join,
	},

	/**
	 * NotFound
	 */
	{
		component: NotFound,
	},
];

export const privateRoutes = [
	{
		path: '/logout',
		component: Logout,
	},

	{
		path: '/glossary',
		exact: true,
		component: Glossary,
	},

	{
		path: '/account/plans',
		exact: true,
		component: Plans,
	},

	{
		path: '/create/:page?',
		exact: true,
		nested: true,
		component: Create,
	},

	{
		path: '/influencer-add/:page?',
		exact: true,
		nested: true,
		component: AddInfluencer,
	},

	{
		path: '/campaign-list',
		exact: true,
		nested: true,
		component: List,
	},
	{
		path: '/',
		exact: true,
		nested: true,
		component: InfluencerList,
	},
	{
		path: '/influencer/:type/:username',
		exact: true,
		nested: true,
		component: InfluencerDetail,
	},
	{
		path: '/campaign/:id',
		exact: true,
		component: CampaignDetail,
	},
];
