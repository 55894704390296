import React from 'react';
import { fromUnixTime, format } from 'date-fns';
import { Icon } from 'interceptd-ui';

const Columns = () => [
	{
		Header: 'Invoice ID',
		id: 'id',
		accessor: 'id',
		cast: 'string',
		minWidth: 200,
	},
	{
		Header: 'Status',
		id: 'status',
		accessor: 'status',
		cast: 'string',
		minWidth: 200,
	},
	{
		Header: 'Date',
		id: 'created',
		accessor: 'created',
		cast: 'string',
		minWidth: 200,
		Cell: ({ value }) => format(fromUnixTime(value), 'dd MMM yy'),
	},
	{
		Header: 'Amount',
		id: 'amount_paid',
		accessor: 'amount_paid',
		cast: 'string',
		minWidth: 200,
		Cell: ({ value }) => `${value}$`,
	},
	{
		Header: 'Credit Card Detail',
		id: 'card',
		accessor: 'card',
		cast: 'string',
		minWidth: 200,
		Cell: ({ value }) => {
			return `(${value?.brand.toUpperCase()}) **** **** **** ${value?.last4}`;
		},
	},
	{
		Header: 'Download',
		id: 'invoice_pdf',
		accessor: 'invoice_pdf',
		cast: 'string',
		minWidth: 100,
		Cell: ({ value }) => (
			<a href={value} target="_blank">
				<Icon i="download" size="15" />
			</a>
		),
	},
];

export default Columns;
