import React from 'react';
import {
	Box,
	PageTitle,
	Icon,
} from 'interceptd-ui';

import Table from '../../components/Table';
import Button from '../../components/Button';
import PlanDetail from '../../components/PlanDetail';

import Columns from './InfluencerColumns';

import useSilo from '../../services/use-silo';

import './styles/InfluencerList.css';

export default function InfluencerList() {
	const { silo } = useSilo();

	return (
		<div className="influencer-list">
			<PageTitle>
				<PageTitle.Title>Influencer List</PageTitle.Title>
				<PlanDetail />
			</PageTitle>

			<Box
				className="box-table-wrapper"
				title={(
					<Button
						mini
						to="/influencer-add"

						className="add-new-campaign-button"
					>
						<Icon i="plus" size="15" /> Add Influencer(s)
					</Button>
				)}
			>
				<Table
					data={silo?.data?.influencers}
					columns={Columns()}
					pageSize={1000}
					PadRowComponent={() => null}
					sortable={false}
					stickyHeader
					defaultSortDesc
					showPageSizeOptions={false}
					showPageJump={false}
					showPagination={false}
					loading={silo?.data?.loading}
				/>
			</Box>
		</div>
	);
}
