import React from 'react';
import PropTypes from 'prop-types';

import './SummaryCell.css';

function SummaryCell({
	className,
	isBlock,
	isThird,
	isForth,
	title,
	renderBefore,
	children,
	...rest
}) {
	return (
		<div className={`summary-cell ${className || ''} ${isBlock ? 'is-block' : ''} ${isThird ? 'is-third' : ''} ${isForth ? 'is-forth' : ''}`} {...rest}>
			{renderBefore && renderBefore()}
			<div>
				{title && <div className="summary-cell-title">{title}</div>}
				<div className="summary-cell-content">{children}</div>
			</div>
		</div>
	);
}

SummaryCell.propTypes = {
	className: PropTypes.string,
	isBlock: PropTypes.bool,
	isThird: PropTypes.bool,
	isForth: PropTypes.bool,
	title: PropTypes.string,
	renderBefore: PropTypes.func,
	children: PropTypes.node.isRequired,
};

SummaryCell.defaultProps = {
	className: '',
	isBlock: false,
	isThird: false,
	isForth: false,
	title: null,
	renderBefore: null,
};

export default React.memo(SummaryCell);
